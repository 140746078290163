import {
	Box,
	Button,
	Grid,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../config/api-service";
import { useConnectorModalContext } from "../connectors.context";
import TestLoader from "./TestLoader";
import "./style.css";

const columns = [
	{
		id: "created_at",
		label: "DATE",
		align: "left",
		format: (value) => {
			return <span>{moment(value).format("YYYY-MM-DD hh:mm A")}</span>;
		},
	},
	{
		id: "test_nm",
		label: "TEST",
		align: "left",
	},
	{
		id: "test_desc",
		label: "DESCRIPTION",
		align: "center",
	},
	{
		id: "jira_task_name",
		label: "JIRA TASK",
		align: "left",
		customFormat: (value) => {
			return (
				<a
					href={value.taskUrl}
					target="_blank"
					rel="noreferrer"
					style={{ textDecoration: "none" }}
				>
					<span style={{ display: "flex", alignItems: "center" }}>
						<span style={{ paddingLeft: "5px" }}>{value.name}</span>
					</span>
				</a>
			);
		},
	},
	{
		id: "assignee_email",
		label: "ASSIGNEE",
		align: "left",
	},
	{
		id: "test_log",
		label: "LOG",
		align: "left",
	},
	{
		id: "test_passed_flg",
		label: "RESULT",
		align: "left",
		format: (value) => {
			return (
				<span className={value === 0 ? "fail-color" : ""}>
					{value === 1 ? "Passed" : value === 0 ? "Failed" : null}
				</span>
			);
		},
	},
];

const TestsTab = () => {
	const [testData, setTestData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [queryParams, setQueryParams] = useState({});
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [test, setTest] = useState(null);
	const [result, setResult] = useState(null);
	const [jiraTask, setJiraTask] = useState(null);
	const [assignee, setAssignee] = useState(null);
	const [searchTests, setSearchTests] = useState("");

	const [testOptions, setTestOptions] = useState([]);
	const [jiraTaskOptions, setJiraTaskOptions] = useState([]);
	const [assigneeOptions, setAssigneeOptions] = useState([]);

	const [orderBy, setOrderBy] = useState("");
	const [orderDirection, setOrderDirection] = useState("asc");

	const { selectedConnector } = useConnectorModalContext();

	useEffect(() => {
		let isMounted = true;
		const fetchAllData = async () => {
			try {
				const [testResponse, jiraTaskResponse, assigneeResponse] =
					await Promise.all([
						ConnectorAPIService.getConnectorTests(
							selectedConnector.ConnectorID
						),
						ConnectorAPIService.getConnectorTestJiraTaskList(
							selectedConnector.ConnectorID
						),
						ConnectorAPIService.getConnectorTestAssigneeEmail(
							selectedConnector.ConnectorID
						),
					]);

				if (isMounted) {
					if (testResponse) {
						setTestOptions(testResponse);
					}
					if (jiraTaskResponse) {
						setJiraTaskOptions(jiraTaskResponse);
					}
					if (assigneeResponse) {
						setAssigneeOptions(assigneeResponse);
					}
				}
			} catch {}
		};

		fetchAllData();
		return () => {
			isMounted = false;
		};
	}, [selectedConnector.ConnectorID]);

	const onChangeFilter = async (target) => {
		//? Handle test filter
		if (target.name === "test") {
			setTest(target.value);
		}
		//? Handle result filter
		if (target.name === "result") {
			setResult(target.value);
		}
		//? Handle jira task filter
		if (target.name === "jira_task") {
			setJiraTask(target.value);
		}
		//? Handle assignee filter
		if (target.name === "assignee") {
			setAssignee(target.value);
		}
		//? Handle search tests filter
		if (target.name === "search_tests") {
			setSearchTests(target.value);
		}
	};

	const handleClearFilter = async () => {
		setStartDate(null);
		setEndDate(null);
		setTest(null);
		setResult(null);
		setJiraTask(null);
		setAssignee(null);
		setSearchTests("");
		setQueryParams({});
	};

	useEffect(() => {
		if (startDate) {
			setQueryParams((prev) => ({
				...prev,
				start_date: moment(startDate).toISOString().slice(0, 10),
			}));
		} else {
			setQueryParams((prev) => {
				if (prev.start_date) {
					delete prev.start_date;
				}
				return { ...prev };
			});
		}

		if (endDate) {
			setQueryParams((prev) => ({
				...prev,
				end_date: moment(endDate).toISOString().slice(0, 10),
			}));
		} else {
			setQueryParams((prev) => {
				if (prev.end_date) {
					delete prev.end_date;
				}
				return { ...prev };
			});
		}

		if (test) {
			setQueryParams((prev) => ({ ...prev, connector_test_id: test }));
		} else {
			setQueryParams((prev) => {
				if (prev.connector_test_id) {
					delete prev.connector_test_id;
				}
				return { ...prev };
			});
		}

		if (result !== null) {
			setQueryParams((prev) => ({ ...prev, test_passed_flg: result }));
		} else {
			setQueryParams((prev) => {
				if (queryParams.hasOwnProperty("test_passed_flg")) {
					delete prev.test_passed_flg;
				}
				return { ...prev };
			});
		}

		if (jiraTask) {
			setQueryParams((prev) => ({ ...prev, jira_task_id: jiraTask }));
		} else {
			setQueryParams((prev) => {
				if (prev.jira_task_id) {
					delete prev.jira_task_id;
				}
				return { ...prev };
			});
		}

		if (assignee) {
			setQueryParams((prev) => ({ ...prev, assignee_email: assignee }));
		} else {
			setQueryParams((prev) => {
				if (prev.assignee_email) {
					delete prev.assignee_email;
				}
				return { ...prev };
			});
		}

		if (searchTests) {
			setQueryParams((prev) => ({ ...prev, test_nm: searchTests }));
		} else {
			setQueryParams((prev) => {
				if (prev.test_nm) {
					delete prev.test_nm;
				}
				return { ...prev };
			});
		}
	}, [startDate, endDate, test, result, jiraTask, assignee, searchTests]);

	useEffect(() => {
		let isMounted = true;

		const getTests = async () => {
			setIsLoading(true);
			try {
				const response = await ConnectorAPIService.getConnectorTests(
					selectedConnector.ConnectorID,
					queryParams
				);
				if (isMounted) {
					if (response) {
						setTestData(response);
					} else {
						setTestData([]);
					}
					setIsLoading(false);
				}
			} catch (error) {
				if (isMounted) {
					setIsLoading(false);
				}
			}
		};

		getTests();

		return () => {
			isMounted = false;
		};
	}, [queryParams, selectedConnector.ConnectorID]);

	const handleSortRequest = (columnId) => {
		const isAsc = orderBy === columnId && orderDirection === "asc";
		setOrderDirection(isAsc ? "desc" : "asc");
		setOrderBy(columnId);
	};

	const sortData = (data, comparator) => {
		const stabilizedThis = data.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	};

	const getComparator = (orderDirection, columnId) => {
		return orderDirection === "desc"
			? (a, b) => descendingComparator(a, b, columnId)
			: (a, b) => -descendingComparator(a, b, columnId);
	};

	const descendingComparator = (a, b, columnId) => {
		if (b[columnId] < a[columnId]) {
			return -1;
		}
		if (b[columnId] > a[columnId]) {
			return 1;
		}
		return 0;
	};

	const sortedTestData = sortData(
		testData,
		getComparator(orderDirection, orderBy)
	);

	return (
		<Box>
			<Grid
				container
				spacing={1}
				sx={{ justifyContent: { xs: "center", md: "space-between" } }}
			>
				<Grid item xs={12} md={3} xl={1.65}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							label="Start Date"
							views={["day"]}
							value={startDate}
							onChange={(newValue) => setStartDate(newValue)}
							renderInput={(params) => (
								<TextField
									sx={{
										minWidth: "100%",
									}}
									error={false}
									size="small"
									{...params}
									helperText={null}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12} md={3} xl={1.65}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							label="End Date"
							views={["day"]}
							value={endDate}
							onChange={(newValue) => setEndDate(newValue)}
							renderInput={(params) => (
								<TextField
									sx={{
										minWidth: "100%",
									}}
									error={false}
									size="small"
									{...params}
									helperText={null}
								/>
							)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={12} md={3} xl={1.4}>
					<Select
						size="small"
						value={test}
						name="test"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={test !== null ? undefined : () => "Test"}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						{testOptions.map((test) => (
							<MenuItem
								key={test.connector_test_id}
								value={test.connector_test_id}
							>
								{test.test_nm}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} md={3} xl={1.3}>
					<Select
						size="small"
						value={result}
						name="result"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={
							result !== null ? undefined : () => "Result"
						}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						<MenuItem value={1}>
							<em>Pass</em>
						</MenuItem>
						<MenuItem value={0}>
							<em>Fail</em>
						</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={12} md={3} xl={1.4}>
					<Select
						size="small"
						value={jiraTask}
						name="jira_task"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={
							jiraTask !== null ? undefined : () => "Jira Task"
						}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						{jiraTaskOptions.map((task) => (
							<MenuItem
								key={task.jira_task_id}
								value={task.jira_task_id}
							>
								{task.jira_key_id}: {task.jira_task_name}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} md={3} xl={1.3}>
					<Select
						size="small"
						value={assignee}
						name="assignee"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						renderValue={
							assignee !== null ? undefined : () => "Assignee"
						}
						sx={{ width: "100%" }}
					>
						<MenuItem value={null}>
							<em>None</em>
						</MenuItem>
						{assigneeOptions.map((assignee) => (
							<MenuItem
								key={assignee.assignee_email}
								value={assignee.assignee_email}
							>
								{assignee.assignee_email}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} md={3} xl={1.65}>
					<TextField
						size="small"
						label="Search Tests"
						value={searchTests}
						name="search_tests"
						onChange={(e) => onChangeFilter(e.target)}
						displayEmpty
						sx={{ width: "100%" }}
					></TextField>
				</Grid>

				<Grid item xs={12} md={3} xl={1.65}>
					<Button
						variant="outlined"
						sx={{ height: 40, width: "100%" }}
						onClick={handleClearFilter}
					>
						CLEAR FILTERS
					</Button>
				</Grid>
			</Grid>

			<TableContainer sx={{ maxHeight: 300, mt: 1 }}>
				<Table stickyHeader dense table size="small">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									sx={{
										backgroundColor: "var(--data-con-blue)",
										color: "#fff",
										fontWeight: 700,
										fontSize: { md: "14px" },
									}}
									key={column.id}
									align={column.align}
									sortDirection={
										orderBy === column.id
											? orderDirection
											: false
									}
								>
									<TableSortLabel
										sx={{
											"&.MuiButtonBase-root": {
												color: "#fff",
												"& .MuiSvgIcon-root": {
													color: "#fff",
												},
											},
										}}
										active={orderBy === column.id}
										direction={
											orderBy === column.id
												? orderDirection
												: "asc"
										}
										onClick={() =>
											handleSortRequest(column.id)
										}
									>
										{column.label}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading ? (
							<TestLoader />
						) : (
							sortedTestData.map((row) => (
								<TableRow
									hover
									tabIndex={-1}
									key={row.connector_test_id}
									sx={{
										"& .MuiTableCell-root": {
											maxWidth: "250px",
											whiteSpace: "unset",
											borderBottom: "none",
										},
									}}
								>
									{columns.map((column) => {
										const value = row[column.id];
										const taskName =
											row.jira_key_id +
											": " +
											row.jira_task_name;
										const taskUrl = row.task_url;

										return (
											<TableCell
												key={column.id}
												align={column.align}
											>
												{column.format
													? column.format(value)
													: column.customFormat
													? column.customFormat({
															name: taskName,
															taskUrl,
													  })
													: value}
											</TableCell>
										);
									})}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{!testData.length && !isLoading && (
				<Typography
					sx={{ color: "red", textAlign: "center", mt: 2 }}
					variant="body"
					component="p"
				>
					No Tests
				</Typography>
			)}
		</Box>
	);
};

export default TestsTab;
