import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { useConnectorModalContext } from "../../connectors.context";

const ObjectLoader = () => {
	const { selectedConnector } = useConnectorModalContext();
	return (
		<>
			<Typography
				sx={{
					textAlign: "left",
					wordBreak: "break-word",
					fontSize: { xs: "12px", md: "14px" },
					color: "var(--data-con-blue)",
					mb: 1,
				}}
			>
				Retrieving the {selectedConnector.ConnectorNM.toLowerCase()}{" "}
				object information...
			</Typography>
			<Skeleton
				variant="rounded"
				height={40}
				width="100%"
				sx={{ borderRadius: "5px", mb: 1 }}
			/>
			<Skeleton
				variant="rounded"
				height={40}
				width="100%"
				sx={{ borderRadius: "5px", mb: 1 }}
			/>
			<Skeleton
				variant="rounded"
				height={40}
				width="100%"
				sx={{ borderRadius: "5px", mb: 1 }}
			/>
			<Skeleton
				variant="rounded"
				height={40}
				width="100%"
				sx={{ borderRadius: "5px" }}
			/>
		</>
	);
};

export default ObjectLoader;
