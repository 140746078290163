import { Badge, CardMedia } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import ConnectorModal from "./ConnectorModal";

export const useAppImage = (app) => {
	const [appImage, setAppImage] = useState(null);

	useEffect(() => {
		const checkImage = (url) => {
			return new Promise((resolve) => {
				const image = new Image();

				image.onload = function () {
					resolve(this.width > 0);
				};

				image.onerror = function () {
					resolve(false);
				};

				image.src = url;
			});
		};
		const fetchImage = async () => {
			try {
				const localImage =
					require(`./../../assets/images/app-icons/${app.SourceImageTXT}`).default;
				setAppImage(localImage);
			} catch (e) {
				if (e.message.includes("Cannot find module")) {
					const isAvailable = await checkImage(app.SourceImageTXT);
					if (isAvailable) {
						setAppImage(app.SourceImageTXT); // Use the valid URL
					} else {
						setAppImage(
							require(`./../../assets/images/app-icons/not_found.png`)
								.default
						);
					}
				} else {
					throw e;
				}
			}
		};
		fetchImage();
	}, [app]);

	return appImage;
};

const ConnectorCard = ({ app }) => {
	const [showConnectorModal, setShowConnectorModal] = useState(false);

	const appImage = useAppImage(app);

	const handleOpen = () => {
		setShowConnectorModal(true);
	};

	const handleClose = () => {
		setShowConnectorModal(false);
	};

	return (
		<>
			<Tooltip title={app?.ConnectorNM}>
				<Badge
					variant="dot"
					invisible={!app.SupportedFLG}
					sx={{
						"& .MuiBadge-badge": {
							right: { xs: 11, sm: 17 },
							top: { xs: 11, sm: 15 },
							backgroundColor: "#4cd964",
							color: "#4cd964",
						},
					}}
				>
					<CardMedia
						component={"img"}
						onClick={handleOpen}
						alt="app"
						image={appImage}
						sx={{
							width: { xs: 95, sm: 120, md: 140 },
							maxHeight: { xs: 63, sm: 80, md: 93 },
							borderRadius: "8px",
							margin: { xs: "2px", sm: "5px" },
							":hover": {
								opacity: 0.3,
								cursor: "pointer",
							},
							display: "inline",
						}}
					/>
				</Badge>
			</Tooltip>
			{showConnectorModal && (
				<ConnectorModal
					showConnectorModal={showConnectorModal}
					handleClose={handleClose}
					connector={app}
					title={app.ConnectorNM}
					appImage={appImage}
				/>
			)}
		</>
	);
};

export default ConnectorCard;
