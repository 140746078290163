import DeleteIcon from "@mui/icons-material/Delete";
import {
	Box,
	Button,
	Divider,
	// FormControl,
	Grid,
	IconButton,
	// InputLabel,
	// MenuItem,
	// Select,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import { ConnectorAPIService } from "../../../../../config/api-service";
import AlertDialog from "../../../../common/dialog";
import { useConnectorModalContext } from "../../../connectors.context";

const Connections = ({ fields, connectorObjectId }) => {
	const [connectionsInfo, setConnectionsInfo] = useState([]);
	const [isAddFieldLoading, setIsAddFieldLoading] = useState(false);
	const [openDeleteFieldDialog, setOpenDeleteFieldDialog] = useState(false);
	const [showFieldAddModal, setShowFieldAddModal] = useState(false);
	const [fieldNameError, setFieldNameError] = useState(false);
	const [clickedItem, setClickedItem] = useState({});
	const [fieldDetails, setFieldDetails] = useState({});

	const { selectedConnector } = useConnectorModalContext();

	useEffect(() => {
		setConnectionsInfo(fields);
	}, [fields]);

	const handleAddField = async () => {
		if (!fieldDetails?.field_nm) {
			setFieldNameError(true);
			return;
		}

		try {
			setIsAddFieldLoading(true);
			const res = await ConnectorAPIService.createObjectField(
				selectedConnector.ConnectorID,
				{
					object_id: connectorObjectId,
					...fieldDetails,
				}
			);

			if (res.success) {
				const fields = await ConnectorAPIService.getConnectorField(
					connectorObjectId
				);
				if (fields) {
					setConnectionsInfo(fields.fieldList);
					setIsAddFieldLoading(false);
					setShowFieldAddModal(false);
					setFieldDetails({});
				}
			}
		} catch {
			setIsAddFieldLoading(false);
		}
	};

	const handleRemoveField = async () => {
		try {
			const { index, data } = clickedItem;
			const res = await ConnectorAPIService.updateObjectField(
				data.connector_object_field_id,
				{ ...data, active_flg: data.active_flg ? 0 : 1 }
			);

			if (res) {
				const newArr = [...connectionsInfo];
				newArr.splice(index, 1);
				setConnectionsInfo(newArr);
				setOpenDeleteFieldDialog(false);
			}
		} catch {}
	};

	const changeConnectionField = (event, index, field) => {
		const newArr = [...connectionsInfo];
		newArr[index][field] = event.target.value;
		setConnectionsInfo(newArr);
	};

	const handleDataUpdated = async (data) => {
		try {
			if (!data.field_nm) {
				const fields = await ConnectorAPIService.getConnectorField(
					connectorObjectId
				);

				if (fields) {
					setConnectionsInfo(fields.fieldList);
				}
				return;
			}
			await ConnectorAPIService.updateObjectField(
				data.connector_object_field_id,
				data
			);
		} catch {}
	};

	const handleFieldAddModalClose = () => {
		setShowFieldAddModal(false);
		setFieldDetails({});
		setFieldNameError(false);
	};

	return (
		<>
			<Divider sx={{ px: { md: 2 }, fontSize: 16, my: 2 }}>
				FIELDS
			</Divider>
			<Box sx={{ textAlign: "center" }}>
				<Button
					variant="contained"
					sx={{
						height: 40,
						minWidth: 150,
						backgroundColor: "var(--data-con-blue)",
					}}
					onClick={() => setShowFieldAddModal(true)}
				>
					ADD FIELD
				</Button>
			</Box>
			{connectionsInfo?.length ? (
				connectionsInfo?.map((endpoint, index) => (
					<Grid
						key={endpoint.connector_object_field_id}
						container
						spacing={2}
						sx={{ px: { md: 2 }, my: 2 }}
					>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<TextField
								label="Field Name"
								variant="outlined"
								size="small"
								value={endpoint?.field_nm}
								onChange={(e) =>
									changeConnectionField(e, index, "field_nm")
								}
								onBlur={() => handleDataUpdated(endpoint)}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									sx: {
										height: 37,
										padding: 1,
									},
									inputProps: {
										sx: {
											padding: 0,
											height: "100%",
											display: "flex",
											alignItems: "center",
											fontSize: "0.875rem",
										},
									},
								}}
								sx={{
									width: "100%",

									padding: 0,
								}}
							/>
						</Grid>
						<Grid item xs={12} md={3} sx={{ gap: 1 }}>
							<TextField
								label="Parameter(s)"
								variant="outlined"
								size="small"
								value={endpoint?.field_parameters}
								onChange={(e) =>
									changeConnectionField(
										e,
										index,
										"field_parameters"
									)
								}
								onBlur={() => handleDataUpdated(endpoint)}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									sx: {
										height: 37,
										padding: 1,
									},
									inputProps: {
										sx: {
											padding: 0,
											height: "100%",
											display: "flex",
											alignItems: "center",
											fontSize: "0.875rem",
										},
									},
								}}
								sx={{
									width: "100%",

									padding: 0,
								}}
							/>
						</Grid>
						{/* <Grid item xs={12} md={2.5} sx={{ gap: 1 }}>
						<FormControl
							size="small"
							sx={{
								minWidth: "100%",
								maxWidth: "100%",
							}}
						>
							<InputLabel
								id="methods"
								sx={{
									fontSize: "0.875rem",
									textAlign: "left",
								}}
							>
								HTTP Method(s)
							</InputLabel>
							<Select
								size="small"
								multiple
								value={endpoint?.method || []}
								onChange={(e) =>
									changeConnectionField(e, index, "method")
								}
								onBlur={() => handleDataUpdated(endpoint)}
								label="HTTP Method(s)"
								labelId="methods"
								renderValue={(selected) => selected.join(", ")}
								sx={{
									fontSize: "0.875rem",
									textAlign: "left",
								}}
							>
								<MenuItem value={"GET"}>GET</MenuItem>
								<MenuItem value={"POST"}>POST</MenuItem>
								<MenuItem value={"PUT"}>PUT</MenuItem>
								<MenuItem value={"PATCH"}>PATCH</MenuItem>
								<MenuItem value={"DELETE"}>DELETE</MenuItem>
							</Select>
						</FormControl>
					</Grid> */}
						<Grid item xs={0.5}>
							<IconButton
								disableRipple
								sx={{ pl: 0 }}
								aria-label="delete"
								onClick={() => {
									setClickedItem({
										index,
										data: endpoint,
									});
									setOpenDeleteFieldDialog(true);
								}}
							>
								<DeleteIcon />
							</IconButton>
						</Grid>
					</Grid>
				))
			) : (
				<Typography
					sx={{
						color: "red",
						textAlign: "center",
						mt: 3,
					}}
					variant="body"
					component="p"
				>
					No Fields
				</Typography>
			)}
			<AlertDialog
				open={openDeleteFieldDialog}
				handleConfirm={handleRemoveField}
				handleClose={() => setOpenDeleteFieldDialog(false)}
			>
				Are you sure you want to delete this field?
			</AlertDialog>
			<AlertDialog
				open={showFieldAddModal}
				handleClose={handleFieldAddModalClose}
				actions={
					<Box sx={{ mt: 2, textAlign: "right" }}>
						<LoadingButton
							onClick={handleAddField}
							loading={isAddFieldLoading}
							variant="contained"
						>
							Save
						</LoadingButton>
					</Box>
				}
				title="Add Field"
				centerAligned={false}
				maxWidth={"md"}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6} sx={{ gap: 1 }}>
						<TextField
							required
							label="Field Name"
							variant="outlined"
							value={fieldDetails?.field_nm}
							onChange={(e) => {
								if (!e.target.value) {
									setFieldNameError(true);
								} else {
									setFieldNameError(false);
								}
								setFieldDetails((prev) => ({
									...prev,
									field_nm: e.target.value,
								}));
							}}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									// padding: 1,
								},
								inputProps: {
									sx: {
										padding: 1.4,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
								"& .MuiFormHelperText-root": {
									ml: 0,
								},
							}}
							error={fieldNameError}
							helperText={
								fieldNameError && "Field name is required"
							}
						/>
					</Grid>
					<Grid item xs={12} md={6} sx={{ gap: 1 }}>
						<TextField
							label="Parameter(s)"
							variant="outlined"
							value={fieldDetails?.field_parameters}
							onChange={(e) =>
								setFieldDetails((prev) => ({
									...prev,
									field_parameters: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									// padding: 1,
								},
								inputProps: {
									sx: {
										padding: 1.4,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
							}}
						/>
					</Grid>
				</Grid>
			</AlertDialog>
		</>
	);
};

export default Connections;
