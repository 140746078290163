import { Skeleton, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { useConnectorModalContext } from "../connectors.context";

const TestLoader = () => {
	const { selectedConnector } = useConnectorModalContext();
	return (
		<>
			<Typography
				sx={{
					wordBreak: "break-word",
					fontSize: { xs: "12px", md: "14px" },
					color: "var(--data-con-blue)",
					py: "10px",
					ml: "17px",
				}}
			>
				Retrieving the {selectedConnector.ConnectorNM.toLowerCase()}{" "}
				test information...
			</Typography>
			<TableRow
				hover
				role="checkbox"
				tabIndex={-1}
				sx={{
					"& .MuiTableCell-root": {
						maxWidth: "unset",
						borderBottom: "none",
					},
				}}
			>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
			</TableRow>
			<TableRow
				hover
				role="checkbox"
				tabIndex={-1}
				sx={{
					"& .MuiTableCell-root": {
						maxWidth: "unset",
						borderBottom: "none",
					},
				}}
			>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
			</TableRow>
			<TableRow
				hover
				role="checkbox"
				tabIndex={-1}
				sx={{
					"& .MuiTableCell-root": {
						maxWidth: "unset",
						borderBottom: "none",
					},
				}}
			>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
				<TableCell align={"left"}>
					<Skeleton width="100%" height={30}></Skeleton>
				</TableCell>
			</TableRow>
		</>
	);
};

export default TestLoader;
