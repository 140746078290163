import { Box, Button, Skeleton, Typography, Grid } from "@mui/material";

const LanguageLoader = () => {
	return (
		<>
			<Grid item xs={12} sm={6} md={3}>
				<Skeleton
					variant="rounded"
					height={40}
					width="100%"
					sx={{ borderRadius: "5px" }}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3}></Grid>

			<Grid item xs={8} sm={4} md={2}></Grid>
		</>
	);
};

export default LanguageLoader;
