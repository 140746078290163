import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
	Box,
	Button,
	CardMedia,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Skeleton,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Complete from "../../../../assets/images/section-status/Complete.png";
import Deployed from "../../../../assets/images/section-status/Deployed.png";
import InProgress from "../../../../assets/images/section-status/InProgress.png";
import Pending from "../../../../assets/images/section-status/Pending.png";
import Roadblocked from "../../../../assets/images/section-status/Roadblocked.png";
import Testing from "../../../../assets/images/section-status/Testing.png";
import { ConnectorAPIService } from "../../../../config/api-service";
import { useConnectorModalContext } from "../../connectors.context";
import ConnectorInfoImageLoader from "./ConnectorInfoImageLoader";
import ConnectorInfoLoader from "./ConnectorInfoLoader";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const changeColor = (defaultInput, userInput) => {
	return userInput !== null && userInput !== defaultInput;
};

export const sectionStatusImageMapper = {
	Roadblocked,
	"In Progress": InProgress,
	Testing,
	Pending,
	Deployed,
	Completed: Complete,
};

const ConnectorInfo = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [activeTask, setActiveTask] = useState(null);
	const [isImageLoading, setIsImageLoading] = useState(false);
	const [connectorInfoStatus, setConnectorInfoStatus] = useState(null);
	const [imageRotation, setImageRotation] = useState(0);

	const {
		selectedConnector,
		connectorInfo,
		setConnectorInfo,
		appImage,
		setAppImage,
		setApiGeneralInfo,
		activeTaskList,
		userInfo,
		sectionStatusList,
		isSectionStatusImageLoading,
		onFieldFocus,
		setOnFieldFocus,
	} = useConnectorModalContext();

	useEffect(() => {
		const task = activeTaskList?.find(
			(activeTask) => activeTask.prime_jira_task_id === 6
		);
		setActiveTask(task);
	}, [activeTaskList]);

	useEffect(() => {
		setIsLoading(true);
		ConnectorAPIService.getConnectorById(selectedConnector.ConnectorID)
			.then((response) => {
				setConnectorInfo((prev) => ({
					...prev,
					connector_name: response.connector.ConnectorNM,
				}));
				setApiGeneralInfo((prev) => ({
					...prev,
					documentation_api_url: response.connector.DocumentationURL,
				}));
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, setApiGeneralInfo, setConnectorInfo]);

	const updateConnectorInfo = async () => {
		setConnectorInfo((prev) => ({
			...prev,
			[onFieldFocus.field]: onFieldFocus.value,
		}));

		try {
			await ConnectorAPIService.updateConnectorInfo(
				connectorInfo.ConnectorInfoID,
				{
					...connectorInfo,
					[onFieldFocus.field]: onFieldFocus.value,
					UpdatedByID: userInfo?.user?.UserID,
				}
			);
		} catch {}

		setOnFieldFocus({
			field: null,
			value: null,
		});
	};

	const handleImageUpdate = async (event) => {
		try {
			setIsImageLoading(true);
			if (event) {
				const response = await ConnectorAPIService.connectorImageUpload(
					selectedConnector.ConnectorID,
					event.target.files[0]
				);

				if (response?.success) {
					setAppImage(response.SourceImageTXT);
					setIsImageLoading(false);
					window.dispatchEvent(new Event("onConnectorImageChange"));
				}
			}
		} catch {
			setIsImageLoading(false);
		}
	};

	useEffect(() => {
		const connectorInfo = sectionStatusList.find(
			(section) => section?.field_mapping_table_nm === "connector_info"
		);
		connectorInfo && setConnectorInfoStatus(connectorInfo.section_status);
	}, [sectionStatusList]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setImageRotation(imageRotation + 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [imageRotation]);

	return (
		<>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
				{isSectionStatusImageLoading ? (
					<Grid>
						<Skeleton variant="circular" width={40} height={40} />
					</Grid>
				) : (
					sectionStatusImageMapper[connectorInfoStatus] && (
						<Tooltip title={connectorInfoStatus}>
							<CardMedia
								component={"img"}
								alt="status"
								image={
									sectionStatusImageMapper[
										connectorInfoStatus
									]
								}
								sx={{
									width: 40,
									height: 40,
									rotate:
										connectorInfoStatus === "In Progress"
											? `${imageRotation * 45}deg`
											: "0deg",
								}}
							/>
						</Tooltip>
					)
				)}

				<Divider
					sx={{
						textTransform: "uppercase",
						fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
						width: "100%",
					}}
				>
					CONNECTOR INFO
				</Divider>
			</Box>

			{isLoading ? (
				<ConnectorInfoLoader />
			) : (
				<Grid
					container
					spacing={2}
					sx={{
						py: 4,
						display: "flex",
						flexDirection: {
							xs: "column",
							md: "row",
						},
						justifyContent: "flex-start",
						textAlign: "center",
					}}
				>
					{isImageLoading ? (
						<ConnectorInfoImageLoader />
					) : (
						<Grid
							item
							xs={12}
							md={2}
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: {
									xs: "center",
									md: "flex-start",
								},
							}}
						>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<Box
									sx={{
										height: 85,
										width: 130,
										mx: { xs: "auto", md: "unset" },
										border: "2px dashed lightgray",
										borderRadius: "10px",
										p: "4px",
										position: "relative",
										overflow: "hidden", // ensure the content doesn't spill outside the box
										"&:hover .hoverButton": {
											display: "flex", // or "block" depending on your needs
										},
										"&:hover .backdrop": {
											display: "block",
										},
									}}
								>
									<CardMedia
										// onClick={handleOpen}
										alt="app"
										image={appImage}
										sx={{
											height: 85,
											width: 130,
											borderRadius: "8px",
										}}
									/>

									<Box
										className="backdrop"
										sx={{
											display: "none",
											position: "absolute",
											top: 0,
											left: 0,
											right: 0,
											bottom: 0,
											backgroundColor:
												"rgba(0, 0, 0, 0.5)",
											borderRadius: "8px",
										}}
									/>

									<Button
										disabled={activeTask}
										className="hoverButton"
										component="label"
										role={undefined}
										variant="contained"
										tabIndex={-1}
										startIcon={<CloudUploadIcon />}
										sx={{
											display: "none", // initially hidden
											flexDirection: "column",
											"& .MuiButton-startIcon": {
												m: 0,
											},
											":hover": {
												backgroundColor: "#00bfff",
												display: "flex",
												flexDirection: "column",
											},
											position: "absolute",
											top: "calc(50% - 24px)", // vertically center the button
											left: "calc(50% - 47px)", // horizontally center the button
										}}
									>
										<VisuallyHiddenInput
											type="file"
											accept="image/*"
											onChange={(event) =>
												handleImageUpdate(event)
											}
										/>
										<Typography
											component={"span"}
											sx={{ fontSize: 9 }}
										>
											Browse File
										</Typography>
									</Button>
								</Box>
							</Tooltip>
						</Grid>
					)}
					<Grid item xs={12} md={10} sx={{ gap: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={5} sx={{ gap: 1 }}>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<TextField
										disabled={activeTask}
										label="Connector Name"
										variant="outlined"
										size="small"
										value={connectorInfo?.connector_name}
										// onChange={(e) =>
										// 	setConnectorInfo((prev) => ({
										// 		...prev,
										// 		connector_name: e.target.value,
										// 	}))
										// }
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											marginBottom: 0.75,
											padding: 0,
										}}
									/>
								</Tooltip>
							</Grid>
							<Grid item xs={12} md={5} sx={{ gap: 1 }}>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<TextField
										disabled={activeTask}
										label="Home URL"
										variant="outlined"
										size="small"
										value={
											onFieldFocus.field ===
											"UserHomeUrlTxt"
												? onFieldFocus.value
												: connectorInfo?.UserHomeUrlTxt ||
												  connectorInfo?.HomeUrlTxt
										}
										onChange={(e) => {
											setConnectorInfo((prev) => ({
												...prev,
												UserHomeUrlTxt: e.target.value,
											}));
											setOnFieldFocus({
												field: "UserHomeUrlTxt",
												value: e.target.value,
											});
										}}
										onBlur={updateConnectorInfo}
										onFocus={(e) => {
											setOnFieldFocus({
												field: "UserHomeUrlTxt",
												value: e.target.value,
											});
										}}
										InputLabelProps={{
											shrink: true,
											sx: {
												color:
													changeColor(
														connectorInfo?.HomeUrlTxt,
														connectorInfo?.UserHomeUrlTxt
													) && "var(--data-con-blue)",
											},
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											marginBottom: 0.75,
											padding: 0,
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													connectorInfo?.HomeUrlTxt,
													connectorInfo?.UserHomeUrlTxt
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									/>
								</Tooltip>
							</Grid>
							<Grid item xs={12} md={2} sx={{ gap: 1 }}>
								<FormControl
									error={
										connectorInfo?.UpdatedByID &&
										!connectorInfo?.UserCategoryDSC
									}
									required
									size="small"
									sx={{
										minWidth: "100%",
										maxWidth: "100%",
									}}
								>
									<InputLabel
										id="Category"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
											color:
												changeColor(
													connectorInfo?.CategoryDSC,
													connectorInfo?.UserCategoryDSC
												) && "var(--data-con-blue)",
										}}
									>
										Category
									</InputLabel>
									<Tooltip
										title={
											activeTask
												? `You can not update this field when the task: ${activeTask?.name} is in an active state`
												: ""
										}
										placement="top"
									>
										<Select
											disabled={activeTask}
											labelId="Category"
											value={
												connectorInfo?.UserCategoryDSC ||
												connectorInfo?.CategoryDSC ||
												""
											}
											onChange={async (e) => {
												setConnectorInfo((prev) => ({
													...prev,
													UserCategoryDSC:
														e.target.value,
												}));
												try {
													await ConnectorAPIService.updateConnectorInfo(
														connectorInfo.ConnectorInfoID,
														{
															...connectorInfo,
															UserCategoryDSC:
																e.target.value,
														}
													);
												} catch {}
											}}
											label="Category"
											sx={{
												fontSize: "0.875rem",
												textAlign: "left",
												".MuiOutlinedInput-notchedOutline":
													changeColor(
														connectorInfo?.CategoryDSC,
														connectorInfo?.UserCategoryDSC
													) && {
														borderColor:
															"var(--data-con-blue)",
													},
											}}
										>
											<MenuItem value={""}>
												<em>None</em>
											</MenuItem>
											<MenuItem value={"DATABASE"}>
												DATABASE
											</MenuItem>
											<MenuItem value={"REST API"}>
												REST API
											</MenuItem>
										</Select>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={10} sx={{ gap: 1 }}>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<TextField
										disabled={activeTask}
										label="Description"
										variant="outlined"
										size="small"
										value={
											onFieldFocus.field ===
											"UserConnectorDSC"
												? onFieldFocus.value
												: connectorInfo?.UserConnectorDSC ||
												  connectorInfo?.ConnectorDSC
										}
										onChange={(e) => {
											setConnectorInfo((prev) => ({
												...prev,
												UserConnectorDSC:
													e.target.value,
											}));
											setOnFieldFocus({
												field: "UserConnectorDSC",
												value: e.target.value,
											});
										}}
										onBlur={updateConnectorInfo}
										onFocus={(e) => {
											setOnFieldFocus({
												field: "UserConnectorDSC",
												value: e.target.value,
											});
										}}
										InputLabelProps={{
											shrink: true,
											sx: {
												color:
													changeColor(
														connectorInfo?.ConnectorDSC,
														connectorInfo?.UserConnectorDSC
													) && "var(--data-con-blue)",
											},
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											marginBottom: 0.75,
											padding: 0,
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													connectorInfo?.ConnectorDSC,
													connectorInfo?.UserConnectorDSC
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									/>
								</Tooltip>
							</Grid>
							<Grid item xs={12} md={2} sx={{ gap: 1 }}>
								<FormControl
									size="small"
									sx={{
										minWidth: "100%",
										maxWidth: "100%",
									}}
								>
									<InputLabel
										id="Industry"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
											color:
												changeColor(
													connectorInfo?.IndustryDSC,
													connectorInfo?.UserIndustryDSC
												) && "var(--data-con-blue)",
										}}
									>
										Industry
									</InputLabel>
									<Tooltip
										title={
											activeTask
												? `You can not update this field when the task: ${activeTask?.name} is in an active state`
												: ""
										}
										placement="top"
									>
										<Select
											disabled={activeTask}
											size="small"
											value={
												connectorInfo?.UserIndustryDSC ||
												connectorInfo?.IndustryDSC ||
												""
											}
											onChange={async (e) => {
												setConnectorInfo((prev) => ({
													...prev,
													UserIndustryDSC:
														e.target.value,
												}));

												try {
													await ConnectorAPIService.updateConnectorInfo(
														connectorInfo.ConnectorInfoID,
														{
															...connectorInfo,
															UserIndustryDSC:
																e.target.value,
														}
													);
												} catch {}
											}}
											label="Industry"
											labelId="Industry"
											sx={{
												fontSize: "0.875rem",
												textAlign: "left",
												".MuiOutlinedInput-notchedOutline":
													changeColor(
														connectorInfo?.IndustryDSC,
														connectorInfo?.UserIndustryDSC
													) && {
														borderColor:
															"var(--data-con-blue)",
													},
											}}
										>
											<MenuItem value={""}>
												<em>None</em>
											</MenuItem>
											{industriesOption.map((option) => (
												<MenuItem
													key={option}
													value={option}
												>
													{option}
												</MenuItem>
											))}
										</Select>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={10} sx={{ gap: 1 }}>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<TextField
										disabled={activeTask}
										label="Tags"
										variant="outlined"
										size="small"
										value={
											onFieldFocus.field === "UserTagsDSC"
												? JSON.parse(
														onFieldFocus.value ||
															"[]"
												  )
												: JSON.parse(
														connectorInfo?.UserTagsDSC ||
															connectorInfo?.TagsDSC ||
															"[]"
												  )
										}
										onChange={(e) => {
											setConnectorInfo((prev) => ({
												...prev,
												UserTagsDSC: JSON.stringify(
													e.target.value
														.split(",")
														.map((tag) =>
															tag.trim()
														)
												),
											}));
											setOnFieldFocus({
												field: "UserTagsDSC",
												value: JSON.stringify(
													e.target.value
														.split(",")
														.map((tag) =>
															tag.trim()
														)
												),
											});
										}}
										onBlur={updateConnectorInfo}
										onFocus={(e) => {
											setOnFieldFocus({
												field: "UserTagsDSC",
												value: JSON.stringify(
													e.target.value
														.split(",")
														.map((tag) =>
															tag.trim()
														)
												),
											});
										}}
										InputLabelProps={{
											shrink: true,
											sx: {
												color:
													changeColor(
														connectorInfo?.TagsDSC,
														connectorInfo?.UserTagsDSC
													) && "var(--data-con-blue)",
											},
										}}
										InputProps={{
											sx: {
												height: 37,
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",
											marginBottom: 0.75,
											padding: 0,
											".MuiOutlinedInput-notchedOutline":
												changeColor(
													connectorInfo?.TagsDSC,
													connectorInfo?.UserTagsDSC
												) && {
													borderColor:
														"var(--data-con-blue)",
												},
										}}
									/>
								</Tooltip>
							</Grid>
							<Grid item xs={12} md={2} sx={{ gap: 1 }}>
								<FormControl
									error={
										connectorInfo?.UpdatedByID &&
										!connectorInfo?.UserTypeDSC
									}
									required
									size="small"
									sx={{
										minWidth: "100%",
										maxWidth: "100%",
									}}
								>
									<InputLabel
										id="Type"
										sx={{
											fontSize: "0.875rem",
											textAlign: "left",
											color:
												changeColor(
													connectorInfo?.TypeDSC,
													connectorInfo?.UserTypeDSC
												) && "var(--data-con-blue)",
										}}
									>
										Type
									</InputLabel>
									<Tooltip
										title={
											activeTask
												? `You can not update this field when the task: ${activeTask?.name} is in an active state`
												: ""
										}
										placement="top"
									>
										<Select
											disabled={activeTask}
											size="small"
											value={
												connectorInfo?.UserTypeDSC ||
												connectorInfo?.TypeDSC ||
												""
											}
											onChange={async (e) => {
												setConnectorInfo((prev) => ({
													...prev,
													UserTypeDSC: e.target.value,
												}));
												try {
													await ConnectorAPIService.updateConnectorInfo(
														connectorInfo.ConnectorInfoID,
														{
															...connectorInfo,
															UserTypeDSC:
																e.target.value,
														}
													);
												} catch {}
											}}
											label="Type"
											labelId="Type"
											sx={{
												fontSize: "0.875rem",
												textAlign: "left",
												".MuiOutlinedInput-notchedOutline":
													changeColor(
														connectorInfo?.TypeDSC,
														connectorInfo?.UserTypeDSC
													) && {
														borderColor:
															"var(--data-con-blue)",
													},
											}}
										>
											<MenuItem value={""}>
												<em>None</em>
											</MenuItem>
											<MenuItem value={"SOURCE"}>
												SOURCE
											</MenuItem>
											<MenuItem value={"DESTINATION"}>
												DESTINATION
											</MenuItem>
											<MenuItem value={"BOTH"}>
												BOTH
											</MenuItem>
										</Select>
									</Tooltip>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

const industriesOption = [
	"Agriculture",
	"Mining",
	"Construction",
	"Manufacturing",
	"Utilities",
	"Transportation",
	"Information Technology",
	"Finance and Insurance",
	"Real Estate",
	"Healthcare",
	"Education",
	"Retail",
	"Wholesale",
	"Hospitality and Tourism",
	"Media and Entertainment",
	"Telecommunications",
	"Energy",
	"Professional Services",
	"Public Administration",
	"Nonprofit and Social Services",
];

export default ConnectorInfo;
