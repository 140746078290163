import { Box, Tab, Tabs, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ConnectorAPIService } from "../../config/api-service";
import Dialog from "../common/dialog";
import AppTab from "./AppTab";
import ConnectorModalHeaderLoader from "./ConnectorModalHeaderLoader";
import ConnectorNonAdminModalContent from "./ConnectorNonAdminModalContent";
import ConnectorNonAdminModalLoader from "./ConnectorNonAdminModalLoader";
import ConnectorModalProvider, {
	useConnectorModalContext,
} from "./connectors.context";
import DevTab from "./DevTab";
import ScriptsTab from "./ScriptsTab";
import TasksTab from "./TasksTab";
import TestsTab from "./TestsTab";

const ConnectorModal = ({
	showConnectorModal,
	handleClose,
	connector,
	title,
	appImage,
	isNewBuild = false,
}) => {
	return (
		<ConnectorModalProvider>
			<Modal
				showConnectorModal={showConnectorModal}
				handleClose={handleClose}
				title={title}
				connector={connector}
				appImage={appImage}
				isNewBuild={isNewBuild}
			/>
		</ConnectorModalProvider>
	);
};

export default ConnectorModal;

function TabPanel(props) {
	const { children, value, index } = props;

	return (
		<div role="tabpanel" hidden={value !== index}>
			{value === index && <Box sx={{ py: 2 }}>{children}</Box>}
		</div>
	);
}

const Modal = ({
	showConnectorModal,
	handleClose,
	connector,
	title,
	appImage,
	isNewBuild,
}) => {
	const [value, setValue] = useState(0);
	const [taskCompleted, setTaskCompleted] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [newBuild, setNewBuild] = useState(isNewBuild);
	const [stage, setStage] = useState([]);
	const [progress, setProgress] = useState([]);
	const [steps, setSteps] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [isNonAdminLoading, setIsNonAdminLoading] = useState(false);
	const [estimatedCompletionDate, setEstimatedCompletionDate] = useState("");

	const {
		setSelectedConnector,
		setAppImage,
		setHasActiveTask,
		userInfo,
		selectedConnector,
	} = useConnectorModalContext();

	useEffect(() => {
		setSelectedConnector(connector);
		setAppImage(appImage);
	}, [connector, appImage, setSelectedConnector, setAppImage]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleModalClose = () => {
		handleClose();
		setValue(0);
		setSelectedConnector({});
		setAppImage("");
		setHasActiveTask(false);
	};

	useEffect(() => {
		ConnectorAPIService.getConnectorBuildStage()
			.then((response) => {
				setStage(response.stages);

				// calculate estimated completion date
				const minutes = response.stages.reduce(
					(total, obj) => total + Number(obj.BuildMinuteAmount),
					0
				);
				let newDateObj = moment(connector.create_dts)
					.add(minutes, "m")
					.toDate();
				setEstimatedCompletionDate(
					moment(newDateObj).format("MM/DD/YYYY")
				);
			})
			.catch((error) => {});
	}, []);

	useEffect(() => {
		if (selectedConnector.ConnectorID) {
			setIsNonAdminLoading(true);
			ConnectorAPIService.getConnectorBuildProgress(
				selectedConnector.ConnectorID
			)
				.then((response) => {
					setProgress(response.connectorBuildProgress);
					setIsNonAdminLoading(false);
				})
				.catch((error) => {
					setIsNonAdminLoading(false);
				});
		}
	}, [selectedConnector.ConnectorID]);

	useEffect(() => {
		let active = 0;
		if (stage.length) {
			const newSteps = stage.map((item) => {
				const isMatched = progress.find(
					(list) =>
						list.ConnectorBuildStageID ===
						item.ConnectorBuildStageID
				);

				if (isMatched) {
					item["ConnectorBuildProgressID"] =
						isMatched.ConnectorBuildProgressID;
					active++;
				}

				return item;
			});

			setSteps(newSteps);
			setActiveStep(active);
		}
	}, [stage, progress]);

	return (
		<>
			{userInfo?.user?.DataconnectorAdminFLG ? (
				<Dialog
					open={showConnectorModal}
					handleClose={handleModalClose}
					title={
						newBuild && isLoading ? (
							<Box
								sx={{
									display: { md: "flex" },
									alignItems: "baseline",
								}}
							>
								<>{title}</>
								<Box sx={{ flexGrow: { md: 1 } }}>
									<Typography
										sx={{
											textAlign: "center",
											color: "var(--data-con-blue)",
										}}
									>
										Please hold on for a moment while we
										build the {title} connector
									</Typography>
								</Box>
							</Box>
						) : (
							title
						)
					}
					centerAligned={false}
					maxWidth={"lg"}
				>
					{isLoading ? (
						<ConnectorModalHeaderLoader />
					) : (
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								flexWrap: "wrap",
								alignItems: "center",
							}}
						>
							<Box sx={{ textAlign: "center" }}>
								<Typography
									variant=""
									sx={{
										fontSize: {
											xs: "30px",
											md: "35px",
											lg: "40px",
										},
									}}
								>
									{taskCompleted}
								</Typography>
								<Typography
									sx={{
										py: 1,
									}}
								>
									Tasks Completed
								</Typography>
							</Box>
							<Box sx={{ textAlign: "center" }}>
								<Typography
									variant=""
									sx={{
										fontSize: {
											xs: "30px",
											md: "35px",
											lg: "40px",
										},
										backgroundColor: "#0000ff33",
										color: "#0000ff",
										borderRadius: "5px",
										p: 1,
									}}
								>
									Active
								</Typography>
								<Typography
									sx={{
										py: 1,
									}}
								>
									Status
								</Typography>
							</Box>
							<Box sx={{ textAlign: "center" }}>
								<Typography
									variant=""
									sx={{
										fontSize: {
											xs: "30px",
											md: "35px",
											lg: "40px",
										},
									}}
								>
									Scout (1/5)
								</Typography>
								<Typography
									sx={{
										py: 1,
									}}
								>
									Phase
								</Typography>
							</Box>
							<Box sx={{ textAlign: "center" }}>
								<Typography
									variant=""
									sx={{
										fontSize: {
											xs: "30px",
											md: "35px",
											lg: "40px",
										},
									}}
								>
									{estimatedCompletionDate}
								</Typography>
								<Typography
									sx={{
										py: 1,
									}}
								>
									Est. Completion
								</Typography>
							</Box>
						</Box>
					)}
					<Box sx={{ width: "100%" }}>
						<Box
							sx={{
								borderBottom: 1,
								borderColor: "divider",
								"&.MuiBox-root": {
									borderBottom: "none",
								},
							}}
						>
							<Tabs
								value={value}
								onChange={handleChange}
								variant="fullWidth"
								// variant="scrollable"
								// scrollButtons
								// allowScrollButtonsMobile
								sx={{
									borderRadius: "5px",
									border: "2px solid var(--data-con-blue)",
									"& .MuiTabs-indicator": {
										display: "none",
									},
									"&.MuiTabs-root": {
										height: "35px",
										minHeight: "unset",
										alignItems: "center",
									},
								}}
							>
								<Tab
									label="TASKS"
									sx={{
										minWidth: "fit-content",
										flex: 1,
										fontWeight: 700,
										fontSize: { md: "20px" },
										color: "var(--data-con-blue)",
										"&.Mui-selected": {
											backgroundColor:
												"var(--data-con-blue)",
											color: "#fff",
										},
									}}
								/>
								<Tab
									label="DEV"
									sx={{
										minWidth: "fit-content",
										flex: 1,
										fontWeight: 700,
										fontSize: { md: "20px" },
										color: "var(--data-con-blue)",
										borderLeft:
											"2px solid var(--data-con-blue)",
										borderRight:
											"2px solid var(--data-con-blue)",
										"&.Mui-selected": {
											backgroundColor:
												"var(--data-con-blue)",
											color: "#fff",
										},
									}}
								/>
								<Tab
									label="APP"
									sx={{
										minWidth: "fit-content",
										flex: 1,
										fontWeight: 700,
										fontSize: { md: "20px" },
										color: "var(--data-con-blue)",
										borderRight:
											"2px solid var(--data-con-blue)",
										"&.Mui-selected": {
											backgroundColor:
												"var(--data-con-blue)",
											color: "#fff",
										},
									}}
								/>
								<Tab
									label="SCRIPTS"
									sx={{
										minWidth: "fit-content",
										flex: 1,
										fontWeight: 700,
										fontSize: { md: "20px" },
										color: "var(--data-con-blue)",
										borderRight:
											"2px solid var(--data-con-blue)",
										"&.Mui-selected": {
											backgroundColor:
												"var(--data-con-blue)",
											color: "#fff",
										},
									}}
								/>
								<Tab
									label="TESTS"
									sx={{
										minWidth: "fit-content",
										flex: 1,
										fontWeight: 700,
										fontSize: { md: "20px" },
										color: "var(--data-con-blue)",
										"&.Mui-selected": {
											backgroundColor:
												"var(--data-con-blue)",
											color: "#fff",
										},
									}}
								/>
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<TasksTab
								isLoading={isLoading}
								setIsLoading={setIsLoading}
								setTaskCompleted={setTaskCompleted}
								newBuild={newBuild}
								setNewBuild={setNewBuild}
							/>
						</TabPanel>
						<TabPanel value={value} index={1}>
							<DevTab />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<AppTab />
						</TabPanel>
						<TabPanel value={value} index={3}>
							<ScriptsTab />
						</TabPanel>
						<TabPanel value={value} index={4}>
							<TestsTab />
						</TabPanel>
					</Box>
				</Dialog>
			) : (
				<Dialog
					open={showConnectorModal}
					handleClose={handleModalClose}
					title={
						newBuild && isLoading ? (
							<Box
								sx={{
									display: { md: "flex" },
									alignItems: "baseline",
								}}
							>
								<>{title}</>
								<Box sx={{ flexGrow: { md: 1 } }}>
									<Typography
										sx={{
											textAlign: "center",
											color: "var(--data-con-blue)",
										}}
									>
										Please hold on for a moment while we
										build the {title} connector
									</Typography>
								</Box>
							</Box>
						) : (
							title
						)
					}
					centerAligned={false}
					maxWidth={"lg"}
				>
					{isNonAdminLoading ? (
						<ConnectorNonAdminModalLoader />
					) : (
						<ConnectorNonAdminModalContent
							steps={steps}
							activeStep={activeStep}
							title={title}
							appImage={appImage}
							progress={progress}
							estimatedCompletionDate={estimatedCompletionDate}
						/>
					)}
				</Dialog>
			)}
		</>
	);
};
