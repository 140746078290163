import {
	Box,
	Button,
	Grid,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ConnectorAPIService } from "../../config/api-service";
import ConnectorCard from "./ConnectorCard";
import ConnectorsLoader from "./ConnectorsLoader";

const ConnectorsComponent = () => {
	const [connectors, setConnectors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [filteredConnectors, setFilteredConnectors] = useState([]);

	const [searchText, setSearchText] = useState("");

	const [connectorType, setConnectorType] = useState("");
	const [industry, setIndustry] = useState("");
	const [developmentPhase, setDevelopmentPhase] = useState("");
	const [status, setStatus] = useState("");

	useEffect(() => {
		setIsLoading(true);
		ConnectorAPIService.getConnectors()
			.then((connectors) => {
				if (connectors && connectors.length) {
					setConnectors(connectors);
					setIsLoading(false);
					setFilteredConnectors(connectors);
				}
			})
			.catch((err) => {
				setIsLoading(false);
			});
	}, []);

	const handleClearFilter = () => {
		setConnectorType("");
		setIndustry("");
		setDevelopmentPhase("");
		setStatus("");
		setSearchText("");
		setFilteredConnectors(connectors);
	};

	useEffect(() => {
		const filteredResults = connectors.filter(
			({ ConnectorNM, SupportedFLG, SourceFLG, DestinationFLG }) => {
				const nameMatches =
					ConnectorNM.toLowerCase().includes(searchText);

				const statusMatches = status === "" || SupportedFLG === status;

				const typeMatches =
					connectorType === "" ||
					connectorType === null ||
					(connectorType === "source" && SourceFLG) ||
					(connectorType === "destination" && DestinationFLG);

				return nameMatches && statusMatches && typeMatches;
			}
		);
		setFilteredConnectors(filteredResults);
	}, [connectors, searchText, status, connectorType]);

	useEffect(() => {
		window.addEventListener("onConnectorImageChange", () => {
			ConnectorAPIService.getConnectors()
				.then((connectors) => {
					if (connectors && connectors.length) {
						setConnectors(connectors);
						setFilteredConnectors(connectors);
					}
				})
				.catch((err) => {});
		});
	}, []);

	return (
		<Box sx={{ px: { xs: 1, sm: 2, md: 5, lg: 10 } }}>
			<Typography variant="h4" sx={{ textAlign: "center", py: 1 }}>
				Connectors ({(connectors?.length || 0).toLocaleString()})
			</Typography>
			<Grid
				container
				sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
			>
				<Grid item sx={{ pr: 1, pb: 1, ml: "5px" }}>
					<TextField
						value={searchText}
						placeholder="Connector Name"
						size="small"
						variant="outlined"
						onChange={(e) =>
							setSearchText(e.target.value.toLowerCase())
						}
					/>
				</Grid>
				<Grid item sx={{ pr: 1, pb: 1, ml: "5px" }}>
					<Select
						size="small"
						value={connectorType}
						onChange={(e) => setConnectorType(e.target.value)}
						displayEmpty
						sx={{ minWidth: 194 }}
					>
						<MenuItem value={""}>Connector Type</MenuItem>
						<MenuItem value={"source"}>Source</MenuItem>
						<MenuItem value={"destination"}>Destination</MenuItem>
					</Select>
				</Grid>
				<Grid item sx={{ pr: 1, pb: 1, ml: "5px" }}>
					<Select
						size="small"
						value={industry}
						onChange={(e) => setIndustry(e.target.value)}
						displayEmpty
						sx={{ minWidth: 194 }}
					>
						<MenuItem value={""}>Industry</MenuItem>
						<MenuItem value={10}>Marketing</MenuItem>
						<MenuItem value={20}>Finance</MenuItem>
						<MenuItem value={30}>Sales</MenuItem>
					</Select>
				</Grid>
				<Grid item sx={{ pr: 1, pb: 1, ml: "5px" }}>
					<Select
						size="small"
						value={developmentPhase}
						onChange={(e) => setDevelopmentPhase(e.target.value)}
						displayEmpty
						sx={{ minWidth: 194 }}
					>
						<MenuItem value={""}>Development Phase</MenuItem>
						<MenuItem value={10}>Phase 1</MenuItem>
						<MenuItem value={20}>Phase 2</MenuItem>
						<MenuItem value={30}>Phase 3</MenuItem>
					</Select>
				</Grid>
				<Grid item sx={{ pr: 1, pb: 1, ml: "5px" }}>
					<Select
						size="small"
						value={status}
						onChange={(e) => setStatus(e.target.value)}
						displayEmpty
						sx={{ minWidth: 194 }}
					>
						<MenuItem value={""}>Status</MenuItem>
						<MenuItem value={1}>Active</MenuItem>
						<MenuItem value={0}>In Development</MenuItem>
					</Select>
				</Grid>
				<Grid item sx={{ pr: 1, pb: 1, ml: "5px" }}>
					<Button
						variant="outlined"
						sx={{ height: 40 }}
						onClick={handleClearFilter}
					>
						CLEAR FILTERS
					</Button>
				</Grid>
			</Grid>

			{isLoading ? (
				<Grid container spacing={1} sx={{ ml: "1px" }}>
					<ConnectorsLoader />
				</Grid>
			) : (
				<Grid container spacing={1}>
					{filteredConnectors.map((connector) => (
						<Grid item xs md="auto" key={connector.ConnectorID}>
							<ConnectorCard app={connector} />
						</Grid>
					))}
				</Grid>
			)}
		</Box>
	);
};

export default ConnectorsComponent;
