import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Skeleton,
	CardMedia
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../../config/api-service";
import Dialog from "../../../common/dialog";
import { useConnectorModalContext } from "../../connectors.context";
import ApiVersionLoader from "./ApiVersionLoader";
import { sectionStatusImageMapper } from "../ConnectorInfo";
import ApiVersionParameterLoader from "./ApiVersionParameterLoader";

const ApiVersion = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [activeTask, setActiveTask] = useState(null);
	const [apiVersions, setApiVersions] = useState([]);
	const [apiVersion, setApiVersion] = useState("");
	const [showParameterAddModal, setShowParameterAddModal] = useState(false);
	const [parameterValue, setParameterValue] = useState({
		parameters: [{ ParameterDESC: "" }],
	});
	const [submitLoading, setSubmitLoading] = useState(false);
	const [apiParameters, setApiParameters] = useState([]);
	const [isParameterLoading, setIsParameterLoading] = useState(false);
	const [parameterInfo, setParameterInfo] = useState({});
	const [apiInfoStatus, setApiInfoStatus] = useState(null);
	const [imageRotation, setImageRotation] = useState(0);

	const { activeTaskList, selectedConnector, sectionStatusList, isSectionStatusImageLoading } = useConnectorModalContext();

	useEffect(() => {
		const task = activeTaskList?.find(
			(activeTask) => activeTask.prime_jira_task_id === 4
		);
		setActiveTask(task);
	}, [activeTaskList]);

	useEffect(() => {
		const apiInfo = sectionStatusList.find(
			(section) =>
				section?.field_mapping_table_nm === "connector_api_version"
		);
		apiInfo && setApiInfoStatus(apiInfo.section_status);
	}, [sectionStatusList]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setImageRotation(imageRotation + 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [imageRotation]);

	useEffect(() => {
		setIsLoading(true);

		ConnectorAPIService.getConnectorApiVersions(
			selectedConnector.ConnectorID
		)
			.then((res) => {
				setApiVersions(res.connectorApiVersions);
				const currentApiVersion = res.connectorApiVersions.find(
					(version) => version.CurrentVersionFLG
				);
				if (currentApiVersion) {
					handleApiVersionChange({
						target: { value: currentApiVersion },
					});
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID]);

	const getApiParameter = (ConnectorApiVersionID) => {
		setIsParameterLoading(true);
		setParameterInfo({});
		ConnectorAPIService.getConnectorApiParameters(ConnectorApiVersionID)
			.then((res) => {
				setApiParameters(res.connectorApiParameters);
				setIsParameterLoading(false);
			})
			.catch((err) => {
				setIsParameterLoading(false);
			});
	};

	const handleApiVersionChange = (e) => {
		setApiVersion(e.target.value);
		if (!e.target.value?.ConnectorApiVersionID) {
			setApiVersion("");
			setApiParameters([]);
			setIsParameterLoading(false);
			return;
		}

		getApiParameter(e.target.value.ConnectorApiVersionID);
	};

	const handleParameterAddModalClose = () => {
		setParameterValue({
			parameters: [{ ParameterDESC: "" }],
		});
		setShowParameterAddModal(false);
	};

	const handleAddParameter = async () => {
		let hasError = false;
		const newVal = parameterValue.parameters.map((parameter) => {
			if (parameter.ParameterDESC === "") {
				hasError = true;
				return { ...parameter, error: "Parameter can not be empty" };
			} else return { ...parameter, error: "" };
		});
		setParameterValue({ ...parameterValue, parameters: newVal });

		if (hasError) {
			return;
		}

		try {
			setSubmitLoading(true);
			const res = await ConnectorAPIService.createConnectorApiParameters(
				apiVersion.ConnectorApiVersionID,
				parameterValue
			);

			if (res.success) {
				setSubmitLoading(false);
				handleParameterAddModalClose();
				getApiParameter(apiVersion.ConnectorApiVersionID);
			}
		} catch {
			setSubmitLoading(false);
		}
	};

	const changeParameter = (event, index, parameter) => {
		setParameterValue((prev) => {
			prev[parameter][index] = { ParameterDESC: event.target.value };
			return { ...prev };
		});
	};

	const handleRemoveParameter = (index, parameter) => {
		const newParameter = { ...parameterValue };
		newParameter[parameter].splice(index, 1);
		setParameterValue(newParameter);
	};

	const handleAddNewParameter = (parameter) => {
		setParameterValue((prev) => {
			return {
				...prev,
				[parameter]: [...prev[parameter], { ParameterDESC: "" }],
			};
		});
	};

	const updateApiVersion = async () => {
		try {
			await ConnectorAPIService.updateConnectorApiVersion(
				apiVersion.ConnectorApiVersionID,
				apiVersion
			);
		} catch {}
	};

	const updateApiVersionParameter = async () => {
		try {
			await ConnectorAPIService.updateConnectorApiParameter(
				parameterInfo.ConnectorApiParameterID,
				{
					ParameterDESC: parameterInfo.ParameterDESC,
				}
			);
		} catch {}
	};

	return (
		<>
			<>
				<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
					{isSectionStatusImageLoading ? (
						<Grid>
							<Skeleton variant="circular" width={40} height={40} />
						</Grid>
					) : (
						sectionStatusImageMapper[apiInfoStatus] && (
							<Tooltip title={apiInfoStatus}>
								<CardMedia
									component={"img"}
									alt="status"
									image={sectionStatusImageMapper[apiInfoStatus]}
									sx={{
										width: 40,
										height: 40,
										rotate:
											apiInfoStatus === "In Progress"
												? `${imageRotation * 45}deg`
												: "0deg",
									}}
								/>
							</Tooltip>
						)
					)}

					<Divider
						sx={{
							textTransform: "uppercase",
							fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
							width: "100%",
						}}
					>
						API VERSION
					</Divider>
				</Box>
				
				{isLoading ? (
					<ApiVersionLoader />
				) : (
					<Box sx={{ my: 4 }}>
						<Box sx={{ textAlign: "center" }}>
							<FormControl size="small">
								<InputLabel id="version">Version</InputLabel>
								<Tooltip
									title={
										activeTask
											? `You can not update this field when the task: ${activeTask?.name} is in an active state`
											: ""
									}
									placement="top"
								>
									<Select
										disabled={activeTask}
										labelId="version"
										value={apiVersion}
										onChange={(e) =>
											handleApiVersionChange(e)
										}
										label="Version"
										sx={{
											minWidth: 200,
											maxWidth: 250,
										}}
										renderValue={(value) =>
											value.VersionDSC
										}
									>
										<MenuItem value={""}>
											<em>None</em>
										</MenuItem>
										{apiVersions.map((apiVersion) => (
											<MenuItem
												key={
													apiVersion.ConnectorApiVersionID
												}
												value={apiVersion}
											>
												{apiVersion.VersionDSC}
											</MenuItem>
										))}
									</Select>
								</Tooltip>
							</FormControl>
						</Box>
						{!!apiVersion && (
							<>
								<Grid container spacing={2} sx={{ py: 4 }}>
									<Grid item xs={12} md={6} sx={{ gap: 1 }}>
										<Tooltip
											title={
												activeTask
													? `You can not update this field when the task: ${activeTask?.name} is in an active state`
													: ""
											}
											placement="top"
										>
											<TextField
												disabled={activeTask}
												label="API Base URL"
												variant="outlined"
												size="small"
												value={
													apiVersion?.ApiBaseURL || ""
												}
												onChange={(e) => {
													setApiVersion((prev) => ({
														...prev,
														ApiBaseURL:
															e.target.value,
													}));
													const newApiVersions =
														apiVersions.map(
															(version) => {
																if (
																	version.ConnectorApiVersionID ===
																	apiVersion.ConnectorApiVersionID
																) {
																	version[
																		"ApiBaseURL"
																	] =
																		e.target
																			.value ||
																		null;
																}
																return version;
															}
														);

													setApiVersions(
														newApiVersions
													);
												}}
												onBlur={updateApiVersion}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													sx: {
														height: 37,
														padding: 1,
													},
													inputProps: {
														sx: {
															padding: 0,
															height: "100%",
															display: "flex",
															alignItems:
																"center",
															fontSize:
																"0.875rem",
														},
													},
												}}
												sx={{
													width: "100%",
													padding: 0,
												}}
											/>
										</Tooltip>
									</Grid>
									<Grid item xs={12} md={6} sx={{ gap: 1 }}>
										<Tooltip
											title={
												activeTask
													? `You can not update this field when the task: ${activeTask?.name} is in an active state`
													: ""
											}
											placement="top"
										>
											<TextField
												disabled={activeTask}
												label="API Headers"
												variant="outlined"
												size="small"
												value={apiVersion?.Header || ""}
												onChange={(e) => {
													setApiVersion((prev) => ({
														...prev,
														Header: e.target.value,
													}));
													const newApiVersions =
														apiVersions.map(
															(version) => {
																if (
																	version.ConnectorApiVersionID ===
																	apiVersion.ConnectorApiVersionID
																) {
																	version[
																		"Header"
																	] =
																		e.target
																			.value ||
																		null;
																}
																return version;
															}
														);

													setApiVersions(
														newApiVersions
													);
												}}
												onBlur={updateApiVersion}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													sx: {
														height: 37,
														padding: 1,
													},
													inputProps: {
														sx: {
															padding: 0,
															height: "100%",
															display: "flex",
															alignItems:
																"center",
															fontSize:
																"0.875rem",
														},
													},
												}}
												sx={{
													width: "100%",

													padding: 0,
												}}
											/>
										</Tooltip>
									</Grid>
									{isParameterLoading ? (
										<ApiVersionParameterLoader />
									) : (
										!!apiParameters.length &&
										apiParameters.map(
											(parameter, index) => (
												<Grid
													item
													xs={12}
													sm={6}
													md={3}
													sx={{ gap: 1 }}
												>
													<Tooltip
														title={
															activeTask
																? `You can not update this field when the task: ${activeTask?.name} is in an active state`
																: ""
														}
														placement="top"
													>
														<TextField
															disabled={
																activeTask
															}
															label={`API Parameter ${
																index + 1
															}`}
															variant="outlined"
															size="small"
															value={
																parameter?.ParameterDESC
															}
															onChange={(e) => {
																setParameterInfo(
																	{
																		ConnectorApiParameterID:
																			parameter.ConnectorApiParameterID,
																		ParameterDESC:
																			e
																				.target
																				.value ||
																			null,
																	}
																);

																const newApiParameters =
																	apiParameters.map(
																		(
																			apiParameter
																		) => {
																			if (
																				apiParameter.ConnectorApiParameterID ===
																				parameter.ConnectorApiParameterID
																			) {
																				apiParameter[
																					"ParameterDESC"
																				] =
																					e
																						.target
																						.value ||
																					null;
																			}
																			return apiParameter;
																		}
																	);

																setApiParameters(
																	newApiParameters
																);
															}}
															onBlur={
																updateApiVersionParameter
															}
															InputLabelProps={{
																shrink: true,
															}}
															InputProps={{
																sx: {
																	height: 37,
																	padding: 1,
																},
																inputProps: {
																	sx: {
																		padding: 0,
																		height: "100%",
																		display:
																			"flex",
																		alignItems:
																			"center",
																		fontSize:
																			"0.875rem",
																	},
																},
															}}
															sx={{
																width: "100%",

																padding: 0,
															}}
														/>
													</Tooltip>
												</Grid>
											)
										)
									)}
								</Grid>

								<Box sx={{ textAlign: "center" }}>
									<Button
										variant="contained"
										sx={{
											height: 40,
											minWidth: 150,
											backgroundColor:
												"var(--data-con-blue)",
										}}
										onClick={() =>
											setShowParameterAddModal(true)
										}
									>
										ADD PARAMETER
									</Button>
								</Box>
							</>
						)}

						<Dialog
							open={showParameterAddModal}
							handleClose={handleParameterAddModalClose}
							actions={
								<Box sx={{ mt: 2, textAlign: "right" }}>
									<LoadingButton
										onClick={handleAddParameter}
										loading={submitLoading}
										variant="contained"
									>
										Save
									</LoadingButton>
								</Box>
							}
							title="Add Parameter"
							centerAligned={false}
							maxWidth={"md"}
						>
							<Grid container spacing={2} sx={{ my: 2 }}>
								{parameterValue?.parameters?.map(
									(parameter, parameterIndex) => (
										<Fragment key={parameterIndex}>
											<Grid
												item
												xs={11.2}
												sx={{ gap: 1 }}
											>
												<TextField
													label={`Api Parameter #${
														parameterIndex + 1
													}`}
													variant="outlined"
													size="small"
													value={
														parameter.ParameterDESC
													}
													onChange={(e) =>
														changeParameter(
															e,
															parameterIndex,
															"parameters"
														)
													}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														sx: {
															height: 37,
															padding: 1,
														},
														inputProps: {
															sx: {
																padding: 0,
																height: "100%",
																display: "flex",
																alignItems:
																	"center",
																fontSize:
																	"0.875rem",
															},
														},
													}}
													sx={{
														width: "100%",
														padding: 0,
														"& .MuiFormHelperText-root":
															{
																ml: 0,
															},
													}}
													error={parameter?.error}
													helperText={
														parameter?.error
													}
												/>
											</Grid>
											{parameterValue.parameters.length >
												1 && (
												<Grid
													item
													xs={0.8}
													sx={{ textAlign: "right" }}
												>
													<IconButton
														disableRipple
														sx={{ px: 0 }}
														aria-label="delete"
														onClick={() =>
															handleRemoveParameter(
																parameterIndex,
																"parameters"
															)
														}
													>
														<DeleteIcon />
													</IconButton>
												</Grid>
											)}
										</Fragment>
									)
								)}
								<Grid
									item
									xs={12}
									md={6}
									sx={{
										gap: 1,
										textAlign: {
											xs: "center",
											md: "unset",
										},
									}}
								>
									<Button
										variant="contained"
										sx={{
											backgroundColor:
												"var(--data-con-blue)",
										}}
										onClick={() =>
											handleAddNewParameter("parameters")
										}
									>
										ADD PARAMETER
									</Button>
								</Grid>
							</Grid>
						</Dialog>
					</Box>
				)}
			</>
		</>
	);
};

export default ApiVersion;
