import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoadingButton } from "@mui/lab";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	Skeleton,
	CardMedia,
	Tooltip
} from "@mui/material";
import { useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../../config/api-service";
import Dialog from "../../../common/dialog";
import { useConnectorModalContext } from "../../connectors.context";
import Connections from "./Connections";
import ConnectionsLoader from "./Connections/ConnectionsLoader";
import ObjectLoader from "./ObjectLoader";
import { sectionStatusImageMapper } from "../ConnectorInfo";

const ApiEndPoints = () => {
	const [expanded, setExpanded] = useState(false);
	const [objects, setObjects] = useState([]);
	const [fields, setFields] = useState({});
	const [isObjectsLoading, setIsObjectsLoading] = useState(false);
	const [isFieldsLoading, setIsFieldsLoading] = useState(false);
	const [showObjectAddModal, setShowObjectAddModal] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [objectDetails, setObjectDetails] = useState({});
	const [apiInfoStatus, setApiInfoStatus] = useState(null);
	const [imageRotation, setImageRotation] = useState(0);

	const { selectedConnector, sectionStatusList, isSectionStatusImageLoading } = useConnectorModalContext();

	useEffect(() => {
		if (selectedConnector.ConnectorID) {
			setIsObjectsLoading(true);
			ConnectorAPIService.getConnectorObject(
				selectedConnector.ConnectorID
			)
				.then((res) => {
					setObjects(res.objectList);
					setIsObjectsLoading(false);
				})
				.catch(() => {
					setIsObjectsLoading(false);
				});
		}
	}, [selectedConnector.ConnectorID]);

	useEffect(() => {
		const apiInfo = sectionStatusList.find(
			(section) =>
				section?.field_mapping_table_nm === "connector_object"
		);
		apiInfo && setApiInfoStatus(apiInfo.section_status);
	}, [sectionStatusList]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setImageRotation(imageRotation + 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [imageRotation]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);

		if (isExpanded) {
			setIsFieldsLoading(true);
			ConnectorAPIService.getConnectorField(panel)
				.then((res) => {
					setFields((prevFields) => ({
						...prevFields,
						[panel]: res.fieldList,
					}));
					setIsFieldsLoading(false);
				})
				.catch(() => {
					setIsFieldsLoading(false);
				});
		}
	};

	const handleObjectAddModalClose = () => {
		setShowObjectAddModal(false);
		setObjectDetails({});
	};

	const handleAddObject = async () => {
		setSubmitLoading(true);
		try {
			const res = await ConnectorAPIService.createObject(
				selectedConnector.ConnectorID,
				{
					...objectDetails,
					object_group_nm: objectDetails.object_type_nm,
				}
			);

			if (res.success) {
				setSubmitLoading(false);
				setShowObjectAddModal(false);
				setObjectDetails({});
				const objects = await ConnectorAPIService.getConnectorObject(
					selectedConnector.ConnectorID
				);
				if (objects) {
					setObjects(objects.objectList);
				}
			}
		} catch {
			setSubmitLoading(false);
		}
	};

	const changeObjectField = (event, index, field) => {
		const newArr = [...objects];
		newArr[index][field] = event.target.value;
		setObjects(newArr);
	};

	const updateObjectField = async (data) => {
		try {
			await ConnectorAPIService.updateObject(
				data.connector_object_id,
				data
			);
		} catch {}
	};

	return (
		<>
		   <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
				{isSectionStatusImageLoading ? (
					<Grid>
						<Skeleton variant="circular" width={40} height={40} />
					</Grid>
				) : (
					sectionStatusImageMapper[apiInfoStatus] && (
						<Tooltip title={apiInfoStatus}>
							<CardMedia
								component={"img"}
								alt="status"
								image={sectionStatusImageMapper[apiInfoStatus]}
								sx={{
									width: 40,
									height: 40,
									rotate:
										apiInfoStatus === "In Progress"
											? `${imageRotation * 45}deg`
											: "0deg",
								}}
							/>
						</Tooltip>
					)
				)}

				<Divider
					sx={{
						textTransform: "uppercase",
						fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
						width: "100%",
					}}
				>
					API ENDPOINTS
				</Divider>
			</Box>
			
			<Box sx={{ textAlign: "center", py: 4 }}>
				<Button
					variant="contained"
					sx={{
						height: 40,
						minWidth: 150,
						backgroundColor: "var(--data-con-blue)",
					}}
					onClick={() => setShowObjectAddModal(true)}
				>
					ADD OBJECT
				</Button>
			</Box>
			{isObjectsLoading ? (
				<ObjectLoader />
			) : !objects.length ? (
				<Typography
					sx={{
						color: "red",
						textAlign: "center",
						mt: 1,
					}}
					variant="body"
					component="p"
				>
					No Objects
				</Typography>
			) : (
				<Box sx={{ border: "1px solid lightgray" }}>
					{objects.map((object, index) => (
						<Accordion
							key={object.connector_object_id}
							expanded={expanded === object.connector_object_id}
							onChange={handleChange(object.connector_object_id)}
							sx={{
								boxShadow: "none",
								backgroundColor: "#e1f8ff",
								"&.Mui-expanded": {
									backgroundColor: "transparent",
								},
							}}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography>{object.object_nm}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid
									container
									spacing={2}
									sx={{ px: { md: 2 }, mb: 2 }}
								>
									<Grid item xs={12} md={4} sx={{ gap: 1 }}>
										<TextField
											label="Object Name"
											variant="outlined"
											value={object?.object_nm}
											onChange={(e) =>
												changeObjectField(
													e,
													index,
													"object_nm"
												)
											}
											onBlur={() =>
												updateObjectField(object)
											}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												sx: {
													// padding: 1,
												},
												inputProps: {
													sx: {
														padding: 1.4,
														height: "100%",
														display: "flex",
														alignItems: "center",
														fontSize: "0.875rem",
													},
												},
											}}
											sx={{
												width: "100%",
												padding: 0,
											}}
										/>
									</Grid>
									<Grid item xs={12} md={4} sx={{ gap: 1 }}>
										<FormControl
											size="small"
											sx={{
												minWidth: "100%",
												maxWidth: "100%",
											}}
										>
											<InputLabel
												id="object_type_nm"
												sx={{
													fontSize: "0.875rem",
													textAlign: "left",
												}}
											>
												Object Type Name
											</InputLabel>
											<Select
												size="small"
												value={
													object?.object_type_nm || ""
												}
												onChange={(e) =>
													changeObjectField(
														e,
														index,
														"object_type_nm"
													)
												}
												onBlur={() =>
													updateObjectField(object)
												}
												label="Object Type Name"
												labelId="object_type_nm"
												sx={{
													fontSize: "0.875rem",
													textAlign: "left",
												}}
											>
												<MenuItem value={""}>
													<em>None</em>
												</MenuItem>
												<MenuItem value={"Entity"}>
													Entity
												</MenuItem>
												<MenuItem value={"Object"}>
													Object
												</MenuItem>
												<MenuItem value={"Report"}>
													Report
												</MenuItem>
												<MenuItem value={"Collection"}>
													Collection
												</MenuItem>
												<MenuItem value={"Document"}>
													Document
												</MenuItem>
												<MenuItem value={"Metadata"}>
													Metadata
												</MenuItem>
												<MenuItem value={"Table"}>
													Table
												</MenuItem>
												<MenuItem value={"View"}>
													View
												</MenuItem>
												<MenuItem value={"Procedure"}>
													Procedure
												</MenuItem>
												<MenuItem value={"Resource"}>
													Resource
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={4} sx={{ gap: 1 }}>
										<TextField
											label="Object Endpoint Url"
											variant="outlined"
											value={object?.object_endpoint_url}
											onChange={(e) =>
												changeObjectField(
													e,
													index,
													"object_endpoint_url"
												)
											}
											onBlur={() =>
												updateObjectField(object)
											}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												sx: {
													// padding: 1,
												},
												inputProps: {
													sx: {
														padding: 1.4,
														height: "100%",
														display: "flex",
														alignItems: "center",
														fontSize: "0.875rem",
													},
												},
											}}
											sx={{
												width: "100%",
												padding: 0,
											}}
										/>
									</Grid>
								</Grid>

								{isFieldsLoading &&
								expanded === object.connector_object_id ? (
									<ConnectionsLoader />
								) : (
									<Connections
										fields={
											fields[object.connector_object_id]
										}
										connectorObjectId={
											object.connector_object_id
										}
									/>
								)}
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			)}

			<Dialog
				open={showObjectAddModal}
				handleClose={handleObjectAddModalClose}
				actions={
					<Box sx={{ mt: 2, textAlign: "right" }}>
						<LoadingButton
							onClick={handleAddObject}
							loading={submitLoading}
							variant="contained"
						>
							Save
						</LoadingButton>
					</Box>
				}
				title="Add Object"
				centerAligned={false}
				maxWidth={"md"}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6} sx={{ gap: 1 }}>
						<TextField
							label="Object Name"
							variant="outlined"
							value={objectDetails?.object_nm}
							onChange={(e) =>
								setObjectDetails((prev) => ({
									...prev,
									object_nm: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									// padding: 1,
								},
								inputProps: {
									sx: {
										padding: 1.4,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6} sx={{ gap: 1 }}>
						<FormControl
							size="small"
							sx={{
								minWidth: "100%",
								maxWidth: "100%",
							}}
						>
							<InputLabel
								id="object_type_nm"
								sx={{
									fontSize: "0.875rem",
									textAlign: "left",
								}}
							>
								Object Type Name
							</InputLabel>
							<Select
								size="small"
								value={objectDetails?.object_type_nm || ""}
								onChange={(e) =>
									setObjectDetails((prev) => ({
										...prev,
										object_type_nm: e.target.value,
									}))
								}
								label="Object Type Name"
								labelId="object_type_nm"
								sx={{
									fontSize: "0.875rem",
									textAlign: "left",
								}}
							>
								<MenuItem value={""}>
									<em>None</em>
								</MenuItem>
								<MenuItem value={"Entity"}>Entity</MenuItem>
								<MenuItem value={"Object"}>Object</MenuItem>
								<MenuItem value={"Report"}>Report</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={6} sx={{ gap: 1 }}>
						<TextField
							label="Object Endpoint Url"
							variant="outlined"
							value={objectDetails?.object_endpoint_url}
							onChange={(e) =>
								setObjectDetails((prev) => ({
									...prev,
									object_endpoint_url: e.target.value,
								}))
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								sx: {
									// padding: 1,
								},
								inputProps: {
									sx: {
										padding: 1.4,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
							}}
						/>
					</Grid>
				</Grid>
			</Dialog>
		</>
	);
};

export default ApiEndPoints;
