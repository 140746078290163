import { Grid, Skeleton, Typography } from "@mui/material";
import { useConnectorModalContext } from "../../connectors.context";

const CredentialsManagementLoader = () => {
	const { selectedConnector } = useConnectorModalContext();
	return (
		<>
			<Typography
				sx={{
					textAlign: "left",
					wordBreak: "break-word",
					fontSize: { xs: "12px", md: "14px" },
					color: "var(--data-con-blue)",
				}}
			>
				Retrieving the {selectedConnector.ConnectorNM.toLowerCase()}{" "}
				credentials management information...
			</Typography>
			<Grid container spacing={2} sx={{ py: 4 }}>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={3} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} sx={{ gap: 1 }}>
					<Skeleton
						variant="rounded"
						height={120}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default CredentialsManagementLoader;
