import { Grid, Skeleton } from "@mui/material";

const ApiVersionParameterLoader = () => {
	return (
		<>
			<Grid item xs={12} sm={6} md={3} sx={{ gap: 1 }}>
				<Skeleton
					variant="rounded"
					height={40}
					width="100%"
					sx={{ borderRadius: "5px" }}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3} sx={{ gap: 1 }}>
				<Skeleton
					variant="rounded"
					height={40}
					width="100%"
					sx={{ borderRadius: "5px" }}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3} sx={{ gap: 1 }}>
				<Skeleton
					variant="rounded"
					height={40}
					width="100%"
					sx={{ borderRadius: "5px" }}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3} sx={{ gap: 1 }}>
				<Skeleton
					variant="rounded"
					height={40}
					width="100%"
					sx={{ borderRadius: "5px" }}
				/>
			</Grid>
		</>
	);
};

export default ApiVersionParameterLoader;
