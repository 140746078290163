import { Grid, Skeleton } from "@mui/material";
import React from "react";

const ConnectorInfoImageLoader = () => {
	return (
		<Grid
			item
			xs={12}
			md={2}
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: {
					xs: "center",
					md: "flex-start",
				},
			}}
		>
			<Skeleton height={150} width={140} />
		</Grid>
	);
};

export default ConnectorInfoImageLoader;
