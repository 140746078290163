import { Box, Skeleton, Typography } from "@mui/material";
import { useConnectorModalContext } from "../../connectors.context";

const ApiVersionLoader = () => {
	const { selectedConnector } = useConnectorModalContext();
	return (
		<Box sx={{ my: 4 }}>
			<Typography
				sx={{
					textAlign: "left",
					wordBreak: "break-word",
					fontSize: { xs: "12px", md: "14px" },
					color: "var(--data-con-blue)",
				}}
			>
				Retrieving the {selectedConnector.ConnectorNM.toLowerCase()} API
				version...
			</Typography>
			<Box sx={{ textAlign: "center" }}>
				<Skeleton
					sx={{ mx: "auto", borderRadius: "5px" }}
					variant="rounded"
					height={40}
					width={200}
				/>
			</Box>
		</Box>
	);
};

export default ApiVersionLoader;
