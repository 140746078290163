import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useConnectorModalContext } from "../../connectors.context";

const TestUserAccountsLoader = () => {
	const { selectedConnector } = useConnectorModalContext();
	return (
		<>
			<Typography
				sx={{
					textAlign: "left",
					wordBreak: "break-word",
					fontSize: { xs: "12px", md: "14px" },
					color: "var(--data-con-blue)",
				}}
			>
				Retrieving the {selectedConnector.ConnectorNM.toLowerCase()}{" "}
				test user accounts information...
			</Typography>
			<Box sx={{ textAlign: "center", py: 4 }}>
				<Skeleton
					sx={{ mx: "auto", borderRadius: "5px" }}
					variant="rounded"
					height={40}
					width={200}
				/>
			</Box>

			<Box
				sx={{
					border: "1px solid lightgray",
					my: 2,
					py: 2,
					borderRadius: "5px",
					position: "relative",
				}}
			>
				<Box
					sx={{
						position: "absolute",
						top: "-10px",
						left: "16px",
						backgroundColor: "white",
						padding: "0 8px",
						fontSize: "14px",
						fontWeight: "bold",
						color: "gray",
					}}
				>
					<Skeleton width={100} />
				</Box>
				<Skeleton
					sx={{
						position: "absolute",
						right: 10,
					}}
					variant="circular"
					width={40}
					height={40}
				/>

				<Skeleton
					sx={{ mx: "auto", mt: 6, mb: 2, borderRadius: "5px" }}
					variant="rounded"
					height={40}
					width={230}
				/>
				<Skeleton
					sx={{ mx: "auto", borderRadius: "5px" }}
					variant="rounded"
					height={40}
					width={160}
				/>
				<Grid
					container
					spacing={2}
					sx={{
						px: { xs: 1, md: 2 },
						my: 2,
						alignItems: "center",
					}}
				>
					<Grid item xs={9} md={5} sx={{ gap: 1 }}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<Skeleton
									sx={{ borderRadius: "5px" }}
									variant="rounded"
									height={40}
									width="100%"
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<Skeleton
									sx={{ borderRadius: "5px" }}
									variant="rounded"
									height={40}
									width="100%"
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={3} md={1} sx={{ gap: 1 }}>
						<Grid item xs={2} md={1}>
							<Skeleton
								variant="circular"
								width={40}
								height={40}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Skeleton
					sx={{ ml: 1, borderRadius: "5px" }}
					variant="rounded"
					height={40}
					width={100}
				/>
			</Box>
		</>
	);
};

export default TestUserAccountsLoader;
