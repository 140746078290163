import React, { useEffect, useState, useCallback } from "react";
import {
	Box,
	Button,
	Grid,
	MenuItem,
	Select,
	Tooltip,
	Typography,
} from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Spinner from "../../../assets/images/app-icons/spinner.svg";
import CodeMirror from "@uiw/react-codemirror";
import { ConnectorAPIService } from "../../../config/api-service";
import { useConnectorModalContext } from "../connectors.context";
import CodeSectionLoader from "./CodeSectionLoader";
import LanguageLoader from "./LanguageLoader";

const ScriptsTab = () => {
	const [isLanguageLoading, setIsLanguageLoading] = useState(false);
	const [branch, setBranch] = useState(null);
	const [branchOptions, setBranchOptions] = useState([]);
	const [isBranchLoading, setIsBranchLoading] = useState(false);
	const [codeSection, setCodeSection] = useState([]);
	const [isCodeSectionLoading, setIsCodeSectionLoading] = useState(false);
	const [previousCode, setPreviousCode] = useState("");
	const [newCode, setNewCode] = useState("");
	const [approveCode, setApproveCode] = useState([]);
	const [copy, setCopy] = useState([]);
	const { selectedConnector } = useConnectorModalContext();
	const [isLoading, setIsLoading] = useState(false);
	const [isDeploying, setIsDeploying] = useState(false);
	const [testStatus, setTestStatus] = useState(null);
	const [isImportsChanged, setIsImportsChanged] = useState(false);
	const [changeList, setChangeList] = useState([]);
	const [initialCodeSection, setInitialCodeSection] = useState([]);
	const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);


	// Define fetchBranchData
	const fetchBranchData = useCallback(async () => {
		if (!selectedConnector) return;

		setIsCodeSectionLoading(true);
		setIsBranchLoading(true);
		try {
			const branchResponse =
				await ConnectorAPIService.getConnectorCodeRepository(
					selectedConnector.ConnectorID
				);
			const branches = branchResponse?.connectorCodeRepositoryList || [];

			setBranchOptions(branches);

			if (branches.length > 0) {
				const initialBranch = branches[0];
				setBranch(initialBranch);

				const codeResponse =
					await ConnectorAPIService.getConnectorGithubCode(
						initialBranch.ConnectorCodeRepository,
						initialBranch.ConnectorCodeBranchNM
					);

				setCodeSection(codeResponse || []);
				setInitialCodeSection(codeResponse || []);
				setChangeList([]);
				const importsSection = codeResponse.find(
					(section) => section.section === "imports"
				);
				if (importsSection) setPreviousCode(importsSection.code);
			}
		} catch (error) {
			console.error("Failed to load data:", error);
		} finally {
			setIsCodeSectionLoading(false);
			setIsBranchLoading(false);
		}
	}, [selectedConnector]);

	// Use fetchBranchData in useEffect
	useEffect(() => {
		fetchBranchData();
	}, [fetchBranchData]);

	const handleBranchChange = async (e) => {
		setBranch(e.target.value);
		if (!e.target.value?.ConnectorCodeRepositoryID) {
			setBranch("");
			return;
		}
		try {
			setIsCodeSectionLoading(true)
			const response = await ConnectorAPIService.getConnectorGithubCode(
				e.target.value.ConnectorCodeRepository,
				e.target.value.ConnectorCodeBranchNM
			);
			setCodeSection(response || []);
			setInitialCodeSection(response || []);
			setChangeList([]);
			setIsCodeSectionLoading(false)
		} catch (error) {
			console.error("Error loading branch code:", error);
		}
	};

	const handleSaveAndTest = async () => {
		if (changeList.length > 0) {
			setIsLoading(true);
			setTestStatus(null);

			const codeData = {
				connectorCodeRepositoryId: branch?.ConnectorCodeRepositoryID,
				regions: changeList.map((section) => ({
					regionName: section.section,
					code: section.code,
				})),
			};

			try {
				const response = await ConnectorAPIService.updateGithubCode(
					codeData
				);
				fetchBranchData();

				if (response?.success) {
					setTestStatus("success");
				} else {
					setTestStatus("error");
				}
			} catch (error) {
				console.error("Error during Save & Test:", error);
				setTestStatus("error");
			} finally {
				setIsLoading(false);
				setIsDeploying(false);
			}
		}
	};

	const handleDeploy = async () => {
		setIsLoading(true);
		setIsDeploying(true);

		const codeData = {
			connectorCodeRepositoryId: branch?.ConnectorCodeRepositoryID,
		};

		try {
			const response = await ConnectorAPIService.deployGithubCode(
				codeData
			);
			fetchBranchData();

			if (response?.success) {
				setTestStatus("success");
			} else {
				setTestStatus("error");
			}
		} catch (error) {
			console.error("Error during Save & Test:", error);
			setTestStatus("error");
		} finally {
			setIsLoading(false);
			setIsDeploying(false);
		}
	};

	// Function to handle code changes
	const handleCodeChange = (newValue, sectionName) => {
		const updatedCodeSection = codeSection.map((s) =>
			s.section === sectionName ? { ...s, code: newValue } : s
		);

		setCodeSection(updatedCodeSection);

		// Compare with the initial code section
		const originalSection = initialCodeSection.find(
			(sec) => sec.section === sectionName
		);

		if (originalSection && originalSection.code !== newValue) {
			// Add or update changed section in changeList
			const updatedChangeList = [...changeList];
			const changeIndex = updatedChangeList.findIndex(
				(sec) => sec.section === sectionName
			);

			if (changeIndex === -1) {
				updatedChangeList.push({
					section: sectionName,
					code: newValue,
				});
			} else {
				updatedChangeList[changeIndex] = {
					section: sectionName,
					code: newValue,
				};
			}

			setChangeList(updatedChangeList);
		} else {
			// Remove from changeList if reverted to initial state
			const updatedChangeList = changeList.filter(
				(sec) => sec.section !== sectionName
			);

			setChangeList(updatedChangeList);
		}
	};

	// Effect to manage the update button state
	useEffect(() => {
		setIsUpdateDisabled(changeList.length === 0);
	}, [changeList]);

	const onConnectorCodeTextUpdate = async (connector_code_id, index) => {
		if (newCode !== previousCode) {
			const response = await ConnectorAPIService.updateConnectorCodeText(
				connector_code_id,
				{ user_connector_code_txt: newCode }
			);
			if (response?.success) {
				const newApproveCode = [...approveCode];
				newApproveCode[index] = 0;
				setApproveCode(newApproveCode);
			}
		}
		const newCopy = [...copy];
		newCopy[index] = false;
		setCopy(newCopy);
		setIsImportsChanged(false);
	};

	return (
		<Box>
			{(isLoading || isDeploying) && (
				<Box
					sx={{
						position: "fixed",
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: "rgba(255, 255, 255, 0.8)",
						zIndex: 1300,
						display: "flex",
						alignItems: "center",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<img src={Spinner} alt="Loading" />
					<Typography
						sx={{
							fontWeight: "bold",
							fontSize: "25px",
							color: "#6CB4EE",
						}}
					>
						{isDeploying
							? "DEPLOYING TO PRODUCTION"
							: "TESTING IN PROGRESS"}
					</Typography>
				</Box>
			)}

			<Grid container spacing={2}>
				{isBranchLoading ? (
					<LanguageLoader />
				) : (
					<Grid
						item
						xs={12}
						md={8}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
						}}
					>
						<Select
							size="small"
							value={branch || ""}
							onChange={handleBranchChange}
							displayEmpty
							sx={{ minWidth: 200 }}
							renderValue={(selected) =>
								selected
									? selected.ConnectorCodeBranchNM
									: "Branch"
							}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{branchOptions.map((branch) => (
								<MenuItem
									key={branch.ConnectorCodeRepositoryID}
									value={branch}
								>
									{branch.ConnectorCodeBranchNM}
								</MenuItem>
							))}
						</Select>

						{/* <Tooltip
							title={
								<Box sx={{ fontSize: "13px", padding: "5px" }}>
									Test 54: Authenticate using OAuth 2.0 failed
									<br />
									Test 55: Get objects failed
									<br />
									Test 67: Load data failed
									<br />
									Test 89: Load test 100k rows failed
									<br />
									Test 90: Load test 1M rows failed
								</Box>
							}
							placement="right-start"
						>
							{testStatus === "success" ? (
								<CheckCircleIcon
									sx={{ color: "green", marginLeft: "10px" }}
								/>
							) : (
								<ErrorIcon
									sx={{ color: "red", marginLeft: "10px" }}
								/>
							)}
						</Tooltip> */}
					</Grid>
				)}
				<Grid
					item
					xs={12}
					md={4}
					sx={{ display: "flex", justifyContent: "flex-end" }}
				>
					{ branch && (
						<>
							<Button
								variant="contained"
								disabled={isUpdateDisabled}
								sx={{
									backgroundColor: !isUpdateDisabled
										? "var(--data-con-blue)"
										: "#eeeeee",
									color: !isUpdateDisabled ? "white" : "#424242",
									"&:hover": {
										backgroundColor: !isUpdateDisabled
											? "var(--data-con-blue)"
											: "#eeeeee",
									},
									mr: 2,
								}}
								onClick={handleSaveAndTest}
							>
								Save & Test
							</Button>

							<Button
								variant="contained"
								disabled={!isUpdateDisabled}
								sx={{
									backgroundColor:
										// testStatus === "success"
										isUpdateDisabled
											? "var(--data-con-blue)"
											: "#eeeeee",
									color:
										// testStatus === "success"
										isUpdateDisabled ? "white" : "#424242",
									"&:hover": {
										backgroundColor:
											// testStatus === "success"
											isUpdateDisabled
												? "var(--data-con-blue)"
												: "#eeeeee",
									},
								}}
								onClick={handleDeploy}
							>
								Deploy to Prod
							</Button>
						</>
        			)}
				</Grid>
			</Grid>

			{isCodeSectionLoading ? (
				<CodeSectionLoader />
			) : (
				codeSection.map((section, index) => (
					<Box
						key={section.section}
						sx={{
							border: "thin solid rgba(0, 0, 0, 0.12)",
							borderRadius: "5px",
							padding: 1,
							paddingTop: "20px",
							mt: 3,
							position: "relative",
							fontSize: "1rem",
						}}
					>
						<Typography
							sx={{
								textTransform: "uppercase",
								color: "rgba(0, 0, 0, 0.50)",
								position: "absolute",
								top: "-12px",
								left: "1rem",
								backgroundColor: "white",
								px: "5px",
							}}
						>
							{section.section}
						</Typography>

						<Box sx={{ display: "flex", justifyContent: "end" }}>
							<Button
								sx={{
									fontSize: "12px",
									textTransform: "none",
									color: "rgba(0, 0, 0, 0.50)",
									p: 0,
								}}
								onClick={() => {
									const newCopy = [...copy];
									newCopy[index] = true;
									setCopy(newCopy);
									navigator.clipboard.writeText(
										section.code || ""
									);
								}}
							>
								{copy[index] ? (
									<DoneOutlinedIcon
										sx={{
											fontSize: "14px",
											marginRight: "2px",
										}}
									/>
								) : (
									<ContentCopyRoundedIcon
										sx={{
											fontSize: "14px",
											marginRight: "2px",
										}}
									/>
								)}
								{copy[index] ? "Copied!" : "Copy code"}
							</Button>
						</Box>

						<CodeMirror
							value={section.code || ""}
							onFocus={() => {
								const newCopy = [...copy];
								newCopy[index] = false;
								setCopy(newCopy);
							}}
							onChange={(value) => {
								handleCodeChange(value, section.section);
								const updatedCodeSection = codeSection.map(
									(s, i) =>
										i === index ? { ...s, code: value } : s
								);

								setCodeSection(updatedCodeSection);
							}}
						/>
					</Box>
				))
			)}
		</Box>
	);
};

export default ScriptsTab;
