import { Grid, Skeleton, Typography } from "@mui/material";
import { useConnectorModalContext } from "../connectors.context";

const CodeSectionLoader = () => {
	const { selectedConnector } = useConnectorModalContext();
	return (
		<>
			<Typography
				sx={{
					textAlign: "left",
					wordBreak: "break-word",
					py: "10px",
					fontSize: { xs: "12px", md: "14px" },
					color: "var(--data-con-blue)",
				}}
			>
				Retrieving the{" "}
				{selectedConnector?.ConnectorNM
					? selectedConnector.ConnectorNM.toLowerCase()
					: "connector"}{" "}
				scripts...
			</Typography>

			<Grid
				container
				spacing={2}
				sx={{
					py: 4,
					display: "flex",
					flexDirection: {
						xs: "column",
						md: "row",
					},
					justifyContent: "flex-start",
					textAlign: "center",
				}}
			>
				<Grid item xs={12}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default CodeSectionLoader;
