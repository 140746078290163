import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import {
	Box,
	CardMedia,
	Grid,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

const ConnectorNonAdminModalContent = ({
	steps,
	activeStep,
	title,
	appImage,
	progress,
	estimatedCompletionDate,
}) => {
	return (
		<div>
			{!!steps.length && activeStep === steps.length && (
				<Box
					sx={{
						color: "rgba(0, 0, 0, 0.6)",
						textAlign: "center",
					}}
				>
					<Typography
						sx={{
							fontSize: {
								xs: "25px",
								md: "30px",
								lg: "35px",
							},
							fontWeight: "200",
						}}
					>
						The {title} Connector <br /> is complete!
					</Typography>
					<Typography
						sx={{
							fontSize: {
								xs: "25px",
								md: "30px",
								lg: "35px",
							},
							fontWeight: "200",
							mt: "1rem",
						}}
					>
						Navigate to the{" "}
						<Link
							to="/pipelines"
							style={{
								color: "var(--data-con-blue)",
								textDeration: "underline",
							}}
						>
							pipelines
						</Link>{" "}
						page to use this connector
					</Typography>
				</Box>
			)}
			{activeStep !== steps.length && (
				<Grid
					container
					spacing={2}
					sx={{
						"& .MuiGrid-item": {
							padding: 0,
						},
					}}
				>
					<Grid item xs={12} md={2}>
						<Box
							sx={{
								height: 85,
								width: 130,
								mx: { xs: "auto", md: "unset" },
								borderRadius: "10px",
								position: "relative",
								overflow: "hidden", // ensure the content doesn't spill outside the box
								"&:hover .hoverButton": {
									display: "flex", // or "block" depending on your needs
								},
								"&:hover .backdrop": {
									display: "block",
								},
							}}
						>
							<CardMedia
								alt="app"
								image={appImage}
								sx={{
									height: 85,
									width: 130,
									borderRadius: "8px",
								}}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={8}>
						<Box
							sx={{
								color: "rgba(0, 0, 0, 0.6)",
								textAlign: "center",
							}}
						>
							<Typography
								sx={{
									fontSize: {
										xs: "30px",
										md: "35px",
										lg: "40px",
									},
									fontWeight: "200",
								}}
							>
								Estimated Completion Date:
							</Typography>
							<Typography
								sx={{
									fontSize: {
										xs: "45px",
										md: "50px",
										lg: "55px",
									},
								}}
							>
								{estimatedCompletionDate}
							</Typography>
						</Box>
					</Grid>
				</Grid>
			)}

			<Box
				sx={{
					width: "100%",
					marginTop: "3rem",
					marginBottom: "10rem",
				}}
			>
				<Stepper
					activeStep={activeStep || -1}
					alternativeLabel
					sx={{
						"& .MuiStepLabel-iconContainer": {
							transform: "scale(2.5)",
						},
						"& .MuiStepConnector-root": {
							transform: "scale(0.8)",
						},
						"& .MuiStepConnector-line": {
							borderTopWidth: "3px",
						},
					}}
				>
					{steps.map((step, index) => (
						<Step
							key={step.ConnectorBuildStageID}
							sx={{
								"& .MuiSvgIcon-root": {
									color: step?.ConnectorBuildProgressID
										? "green"
										: progress.length === index
										? "var(--data-con-blue)"
										: "#bdbdbd",
								},
							}}
						>
							<StepLabel
								sx={{
									"& .MuiStepLabel-label": {
										fontSize: "20px",
										color: "rgba(0, 0, 0, 0.6) !important",
										marginTop: "2rem !important",
									},
								}}
								StepIconComponent={
									step?.ConnectorBuildProgressID
										? CheckCircleIcon
										: progress.length === index
										? WatchLaterIcon
										: CircleOutlinedIcon
								}
							>
								{step.ConnectorBuildStageNM}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</Box>
		</div>
	);
};

export default ConnectorNonAdminModalContent;
