import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { useConnectorModalContext } from "../../connectors.context";

const AuthenticationLoader = () => {
	const { selectedConnector } = useConnectorModalContext();
	return (
		<>
			<Typography
				sx={{
					textAlign: "left",
					wordBreak: "break-word",
					fontSize: { xs: "12px", md: "14px" },
					color: "var(--data-con-blue)",
				}}
			>
				Retrieving the {selectedConnector.ConnectorNM.toLowerCase()}{" "}
				authentication information...
			</Typography>
			<Box sx={{ mt: 4, textAlign: "center" }}>
				<Skeleton
					sx={{ mx: "auto", borderRadius: "5px" }}
					variant="rounded"
					height={40}
					width={200}
				/>
			</Box>
			<Grid container spacing={1} sx={{ py: 4 }}>
				<Grid item xs={12} md={6}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Skeleton
						variant="rounded"
						height={40}
						width="100%"
						sx={{ borderRadius: "5px" }}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default AuthenticationLoader;
